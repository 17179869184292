import { colors } from "@components/Theme/colors";
import { Box, Container, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";
export interface VisionBlockProps {
  block: APITypes.Components.ContentBlocks.VisionBlock;
}
const VisionBlock = ({
  block
}: VisionBlockProps) => {
  const {
    title,
    description
  } = block;
  return <Box sx={{
    position: "relative",
    width: "100%",
    backgroundColor: colors.greyColor.main
  }} data-sentry-element="Box" data-sentry-component="VisionBlock" data-sentry-source-file="VisionBlock.tsx">
      <Box sx={{
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 1,
      display: {
        xs: "none",
        lg: "flex"
      },
      height: "100%"
    }} data-sentry-element="Box" data-sentry-source-file="VisionBlock.tsx">
        <Stack flexDirection="row" data-sentry-element="Stack" data-sentry-source-file="VisionBlock.tsx">
          <svg width="105" viewBox="-1 0 105 620" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="VisionBlock.tsx">
            <path d="M0 0L39.9953 630H104.161L64.0575 0H0Z" fill="#E14C8B" data-sentry-element="path" data-sentry-source-file="VisionBlock.tsx" />
          </svg>
          <svg width="140" viewBox="0 0 140 630" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="VisionBlock.tsx">
            <path d="M0.405273 0L40.4006 630H139.251L99.2554 0H0.405273Z" fill="#13E0B9" data-sentry-element="path" data-sentry-source-file="VisionBlock.tsx" />
          </svg>
          <svg width="123" viewBox="0 0 123 630" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="VisionBlock.tsx">
            <path d="M0.603516 0L40.5988 630H175L134.897 0H0.603516Z" fill="#026CFF" data-sentry-element="path" data-sentry-source-file="VisionBlock.tsx" />
          </svg>
        </Stack>
      </Box>
      <Container maxWidth="sm" disableGutters data-sentry-element="Container" data-sentry-source-file="VisionBlock.tsx">
        <Stack justifyContent={"center"} alignItems={"center"} gap={4} py={{
        xs: 2,
        md: 8
      }} data-sentry-element="Stack" data-sentry-source-file="VisionBlock.tsx">
          <Typography component={"div"} variant="h2" align="center" textAlign="center" sx={{
          "& p": {
            margin: "0"
          },
          display: "flex",
          justifyContent: "center" /* Align horizontal */,
          alignItems: "center" /* Align vertical */
        }} data-sentry-element="Typography" data-sentry-source-file="VisionBlock.tsx">
            <SanitizeString content={title} data-sentry-element="SanitizeString" data-sentry-source-file="VisionBlock.tsx" />
          </Typography>
          <Typography align="center" component={"div"} sx={{
          "& p": {
            margin: "0"
          }
        }} data-sentry-element="Typography" data-sentry-source-file="VisionBlock.tsx">
            <SanitizeString content={description} data-sentry-element="SanitizeString" data-sentry-source-file="VisionBlock.tsx" />
          </Typography>
        </Stack>
      </Container>
    </Box>;
};
export default VisionBlock;