import * as Yup from "yup";

export const digitsOnly = (value: string) => /^[+]?\d+$/.test(value);

export const FILE_SIZE = 1024 * 1024 * 8;

export const SUPPORTED_FORMATS = [
  "application/msword",
  "application/vnd.ms-excel",
  "application/pdf",
  "text/plain",
  "text/html",
  "text/html",
  "application/rtf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "image/jpeg",
  "image/x-icon",
  "image/jpeg",
  "image/png",
  "message/rfc822",
  "message/rfc822",
  "application/vnd.ms-outlook",
  "application/mbox",
  "image/heic",
  "image/heif",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
];

export const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  number: Yup.string()
    .required("Please enter valid number")
    .test("Digits only", "Digits only", digitsOnly)
    .min(7, "Please enter valid number")
    .max(15, "Maximum number limits"),
  track: Yup.string().required("Track is required"),
  cv: Yup.mixed<File>()
    .required("CV is required")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      return !value || (value && SUPPORTED_FORMATS.includes(value.type));
    }),
  contact: Yup.bool()
    .oneOf([true], "Select is required")
    .required("Select is required"),
  privacy: Yup.bool()
    .oneOf([true], "Select is required")
    .required("Select is required"),
}).required();
