import { Typography } from "@mui/material";
export interface IRegularText {
  text?: string;
  sx?: Record<string, any>;
  children?: any;
}
export default function RegularText({
  text,
  sx,
  children
}: IRegularText) {
  return <Typography component={text ? "p" : "div"} sx={{
    ...sx
  }} data-sentry-element="Typography" data-sentry-component="RegularText" data-sentry-source-file="RegularText.tsx">
      {text ?? children}
    </Typography>;
}