import LandingPageHero from "@components/blocks/LandingPageHero/LandingPageHero";
import { APITypes } from "utils/api.types";
export interface HeroITClusterProps {
  block: APITypes.Components.ContentBlocks.HeroITCluster;
}
const HeroITCluster = ({
  block
}: HeroITClusterProps) => {
  const {
    hero
  } = block;
  return <LandingPageHero block={{
    ...block.hero,
    heroTitle: hero.heroSquareBlock.title,
    heroSquareBlock: {
      ...hero.heroSquareBlock,
      subTitle: hero.heroSquareBlock.subTitle
    }
  } as any} data-sentry-element="LandingPageHero" data-sentry-component="HeroITCluster" data-sentry-source-file="HeroITCluster.tsx" />;
};
export default HeroITCluster;