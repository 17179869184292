import { colors } from "@components/Theme/colors";
import theme from "@components/Theme/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/skyblue";
import NextImage from "next/image";
import { useEffect, useRef, useState } from "react";
import { APITypes } from "utils/api.types";
import { fetchInstagramPosts } from "utils/fetchingFunctions";
import { SanitizeString } from "utils/SanitizeString";
export interface InstagramBlockProps {
  block: APITypes.Components.ContentBlocks.InstagramBlock;
}
interface InstagramPost {
  id: string;
  caption: string;
  media_url: string;
  media_type: string;
  timestamp: string;
  thumbnail_url?: string;
  permalink?: string;
}
export const InstagramBlock = ({
  block
}: InstagramBlockProps) => {
  const {
    title,
    description
  } = block;
  const mainRef = useRef<Splide>(null);
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.down("md"));
  const [posts, setPosts] = useState<InstagramPost[]>([]);
  const slidesPerPage = isExtraSmallSize ? 1 : 4;
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchInstagramPosts();
      const sortedPosts: InstagramPost[] = (res.data || []).sort((postA: InstagramPost, postB: InstagramPost) => new Date(postB.timestamp).getTime() - new Date(postA.timestamp).getTime());
      setPosts(sortedPosts);
    };
    fetchData();
  }, []);
  const handleBack = () => {
    if (mainRef.current) {
      mainRef.current.go("<");
    }
  };
  const handleNext = () => {
    if (mainRef.current) {
      mainRef.current.go(">");
    }
  };
  if (!posts || posts && posts.length === 0) return null;
  return <Box py={{
    xs: 10,
    background: colors.white.main
  }} data-sentry-element="Box" data-sentry-component="InstagramBlock" data-sentry-source-file="InstagramBlock.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="InstagramBlock.tsx">
        <Stack gap={4} data-sentry-element="Stack" data-sentry-source-file="InstagramBlock.tsx">
          <Stack flexDirection={{
          xs: "column",
          lg: "row"
        }} justifyContent={"space-between"} gap={2} data-sentry-element="Stack" data-sentry-source-file="InstagramBlock.tsx">
            <Stack maxWidth={"582px"} data-sentry-element="Stack" data-sentry-source-file="InstagramBlock.tsx">
              <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="InstagramBlock.tsx">{title}</Typography>
              <Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="InstagramBlock.tsx"><SanitizeString content={description} data-sentry-element="SanitizeString" data-sentry-source-file="InstagramBlock.tsx" /></Typography>
            </Stack>
            <Stack flexDirection={"row"} justifyContent={{
            xs: "end",
            md: "start"
          }} gap={2} sx={{
            height: "fit-content"
          }} data-sentry-element="Stack" data-sentry-source-file="InstagramBlock.tsx">
              <Button sx={{
              px: 1,
              py: 0.5
            }} variant="contained" onClick={handleBack} data-sentry-element="Button" data-sentry-source-file="InstagramBlock.tsx">
                <ArrowBackIcon sx={{
                p: 0,
                m: 0,
                width: "20px"
              }} fontSize="small" data-sentry-element="ArrowBackIcon" data-sentry-source-file="InstagramBlock.tsx" />
              </Button>
              <Button sx={{
              px: 1,
              py: 0.5
            }} onClick={handleNext} variant="contained" data-sentry-element="Button" data-sentry-source-file="InstagramBlock.tsx">
                <ArrowForwardIcon sx={{
                p: 0,
                m: 0,
                width: "20px"
              }} fontSize="small" data-sentry-element="ArrowForwardIcon" data-sentry-source-file="InstagramBlock.tsx" />
              </Button>
            </Stack>
          </Stack>
          <Stack sx={{
          "& .splide": {
            padding: 0
          }
        }} data-sentry-element="Stack" data-sentry-source-file="InstagramBlock.tsx">
            <Splide ref={mainRef} options={{
            type: "loop",
            perPage: slidesPerPage,
            arrows: false,
            pagination: false,
            gap: "1rem",
            perMove: 1
          }} data-sentry-element="Splide" data-sentry-source-file="InstagramBlock.tsx">
              {posts.map((item, i) => <SplideSlide key={item.id} onClick={() => window.open(item.permalink ?? item.media_url, "_blank")} style={{
              minWidth: "301px",
              minHeight: "310px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer"
            }}>
                  <NextImage alt={item.caption} width={301} height={310}
              //sizes="100vw"
              src={item.thumbnail_url ?? item.media_url} style={{
                objectFit: "cover"
              }} />
                </SplideSlide>)}
            </Splide>
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default InstagramBlock;