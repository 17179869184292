import { colors } from "@components/Theme/colors";
import { Box, Container, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import { Image } from "components";
import { APITypes } from "utils/api.types";
export interface TrajectITClusterProps {
  block: APITypes.Components.ContentBlocks.TrajectITCluster;
}
const TrajectITCluster = ({
  block
}: TrajectITClusterProps) => {
  const {
    image,
    list,
    hexTextColor,
    hexBackgroundColor
  } = block;
  return <Box sx={{
    backgroundColor: hexBackgroundColor,
    color: hexTextColor
  }} pt={{
    xs: 8,
    lg: 8
  }} pb={{
    xs: 8,
    lg: 15
  }} data-sentry-element="Box" data-sentry-component="TrajectITCluster" data-sentry-source-file="TrajectITCluster.tsx">
      <Grid container alignItems="center" justifyContent="center" sx={{
      flexDirection: {
        xs: "column",
        md: "row"
      }
    }} data-sentry-element="Grid" data-sentry-source-file="TrajectITCluster.tsx">
        <Grid item xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="TrajectITCluster.tsx">
          <Container maxWidth="sm" data-sentry-element="Container" data-sentry-source-file="TrajectITCluster.tsx">
            <Stack gap="16px" data-sentry-element="Stack" data-sentry-source-file="TrajectITCluster.tsx">
              <Typography variant="h2" style={{
              color: "white",
              maxWidth: "400px"
            }} data-sentry-element="Typography" data-sentry-source-file="TrajectITCluster.tsx">
                {list.title}
              </Typography>
              <List sx={{
              pl: {
                xs: 0,
                md: 20
              }
            }} data-sentry-element="List" data-sentry-source-file="TrajectITCluster.tsx">
                {list.listItem.map((item, i) => <ListItem key={i} sx={{
                display: "list-item"
              }}>
                    <Typography sx={{
                  marginLeft: "-24px",
                  fontSize: "18px",
                  fontWeight: 700
                }}>
                      <span>
                        <span style={{
                      fontSize: "26px",
                      marginRight: "4px",
                      color: colors.secondary.main
                    }}>
                          {i + 1}
                        </span>{" "}
                        {item.title}
                      </span>
                    </Typography>
                    <Typography sx={{
                  fontSize: "18px",
                  fontWeight: 400
                }}>
                      {item.subTitle}
                    </Typography>
                  </ListItem>)}
              </List>
            </Stack>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="TrajectITCluster.tsx">
          <Image image={image} alt={image?.name} layout={"responsive"} data-sentry-element="Image" data-sentry-source-file="TrajectITCluster.tsx" />
        </Grid>
      </Grid>
    </Box>;
};
export default TrajectITCluster;