import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import { Box, Container, Paper, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
export interface IQuoteWithPicture {
  block: APITypes.Components.ContentBlocks.QuoteWithPicture;
}
export default function QuoteWithPicture({
  block
}: IQuoteWithPicture) {
  const {
    image,
    quote,
    backgroundColor,
    personName,
    textColor
  } = block;
  const quoteWithoutQuotationMarks = quote.replace(/\"/g, "");
  return <Box pt={5} pb={5} margin={2} sx={{
    backgroundColor: backgroundColor
  }} data-sentry-element="Box" data-sentry-component="QuoteWithPicture" data-sentry-source-file="QuoteWithPicture.tsx">
      <Container disableGutters maxWidth={false} sx={{
      height: "100%",
      display: "flex",
      flexDirection: {
        lg: "row",
        sm: "column",
        xs: "column"
      },
      backgroundColor: backgroundColor
    }} data-sentry-element="Container" data-sentry-source-file="QuoteWithPicture.tsx">
        <Box sx={{
        height: "100%",
        maxWidth: "500px",
        display: "flex",
        alignItems: "center"
      }} data-sentry-element="Box" data-sentry-source-file="QuoteWithPicture.tsx">
          <Paper sx={{
          height: "100%",
          backgroundColor: colors.white.main,
          color: colors.primary.main,
          padding: "40px",
          border: "none",
          boxShadow: "none",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: 4
        }} data-sentry-element="Paper" data-sentry-source-file="QuoteWithPicture.tsx">
            <Typography align="center" variant="body1" sx={{
            "& .quotes": {
              color: colors.pink.main
            },
            fontSize: "1.5rem"
          }} data-sentry-element="Typography" data-sentry-source-file="QuoteWithPicture.tsx">
              <span className="quotes">&quot;</span>
              {quoteWithoutQuotationMarks}
              <span className="quotes">&quot;</span>
            </Typography>
            <RegularText sx={{
            color: colors.pink.main,
            fontSize: "14px"
          }} text={`──  ${personName}`} data-sentry-element="RegularText" data-sentry-source-file="QuoteWithPicture.tsx" />
          </Paper>
        </Box>
      </Container>
    </Box>;
}