import { Card, CardContent, CardHeader, Container } from "@mui/material";
export interface NotFoundBlock {
  title: string;
  content?: string;
}
export const NotFoundBlock = ({
  title,
  content
}: NotFoundBlock) => {
  return <Container sx={{
    my: 10
  }} data-sentry-element="Container" data-sentry-component="NotFoundBlock" data-sentry-source-file="NotFoundBlock.tsx">
      <Card data-sentry-element="Card" data-sentry-source-file="NotFoundBlock.tsx">
        <CardHeader title={title} data-sentry-element="CardHeader" data-sentry-source-file="NotFoundBlock.tsx" />
        {content && <CardContent>{content}</CardContent>}
      </Card>
    </Container>;
};