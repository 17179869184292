import { Box, Link } from "@mui/material";
import { Image } from "components";
import { APITypes } from "utils/api.types";
export interface ImageBlockProps {
  block: APITypes.Components.ContentBlocks.ImageBlock;
}
const ImageBlock = ({
  block
}: ImageBlockProps) => {
  const {
    image,
    href
  } = block;
  return <Box data-sentry-element="Box" data-sentry-component="ImageBlock" data-sentry-source-file="ImageBlock.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="ImageBlock.tsx">
        {href != null ? <Link target="_blank" href={href}>
            <Image image={image} alt={image?.name} layout={"responsive"} />
          </Link> : <Image image={image} alt={image?.name} layout={"responsive"} />}
      </Box>
    </Box>;
};
export default ImageBlock;