import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Stack, Typography } from "@mui/material";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";
export interface StepperFlowBlockProps {
  block: APITypes.Components.ContentBlocks.StepperFlow;
}
const StepperFlowBlock = ({
  block
}: StepperFlowBlockProps) => {
  const {
    title,
    description,
    list,
    button
  } = block;
  return <Box py={{
    xs: 6,
    md: 14
  }} sx={{
    backgroundColor: colors.greyColor.light
  }} data-sentry-element="Box" data-sentry-component="StepperFlowBlock" data-sentry-source-file="StepperFlowBlock.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="StepperFlowBlock.tsx">
        <Stack alignItems="start" justifyContent="flex-start" gap={{
        xs: 4,
        md: 12
      }} flexDirection={{
        xs: "column",
        md: "row"
      }} data-sentry-element="Stack" data-sentry-source-file="StepperFlowBlock.tsx">
          <Stack sx={{
          flex: 1
        }} gap={2} data-sentry-element="Stack" data-sentry-source-file="StepperFlowBlock.tsx">
            <Typography variant="h2" align="left" data-sentry-element="Typography" data-sentry-source-file="StepperFlowBlock.tsx">
              <SanitizeString content={title} data-sentry-element="SanitizeString" data-sentry-source-file="StepperFlowBlock.tsx" />
            </Typography>
            {description && <Typography component={"div"} sx={{
            ml: {
              xs: 0,
              md: 8
            }
          }}>
                <SanitizeString content={description} />
              </Typography>}
          </Stack>
          <Stack sx={{
          flex: 2
        }} gap={2} data-sentry-element="Stack" data-sentry-source-file="StepperFlowBlock.tsx">
            {list.map((item, i) => <Stack key={i} sx={{
            flex: 1,
            width: "100%"
          }}>
                <Accordion defaultExpanded={true} sx={{
              boxShadow: "none",
              backgroundColor: colors.greyColor.main
            }}>
                  <AccordionSummary expandIcon={<ExpandLessIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{
                "& .MuiAccordionSummary-expandIconWrapper": {
                  color: colors.white.main,
                  backgroundColor: colors.secondary.main,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  "& .MuiSvgIcon-root": {
                    fontSize: 40
                  }
                }
              }}>
                    <Box sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: colors.green.main,
                  py: 1,
                  px: 2
                }}>
                      <Typography>{i + 1}</Typography>
                    </Box>
                    <Typography variant="h5" sx={{
                  color: colors.primary.main,
                  pr: 3,
                  ml: 6
                }}>
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component={"div"}>
                      <Markdown content={item.description} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Stack>)}
            <Stack pt={4} flexDirection={"row"} justifyContent={"flex-end"} data-sentry-element="Stack" data-sentry-source-file="StepperFlowBlock.tsx">
              <Button variant="contained" component={NextLink} href={button.href} data-sentry-element="Button" data-sentry-source-file="StepperFlowBlock.tsx">
                {button.title}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default StepperFlowBlock;