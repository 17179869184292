import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import TitleBig from "@components/typography/TitleBig";
import { Box, Container, Stack } from "@mui/material";
import { APITypes } from "utils/api.types";
import IconBlockRegular from "./IconBlockRegular";
export interface IIconsWithTextblockProps {
  block: APITypes.Components.ContentBlocks.IconsWithTextBlock;
}
export default function IconsWithTextBlockProps({
  block
}: IIconsWithTextblockProps) {
  const {
    title,
    description,
    iconBlock,
    layout,
    iconBlockTitle
  } = block;
  return <Box py={{
    xs: 8,
    md: 14
  }} sx={{
    backgroundColor: colors.greyColor.main
  }} data-sentry-element="Box" data-sentry-component="IconsWithTextBlockProps" data-sentry-source-file="IconsWithTextblock.tsx">
      {layout === "regular" && <Container>
          <Stack gap={2}>
            {title && <TitleBig text={title} sx={{
          color: "black",
          align: "center",
          textAlign: "center"
        }} />}
            {description && <RegularText sx={{
          align: "center",
          textAlign: "center"
        }}>
                <Markdown content={description} />
              </RegularText>}
          </Stack>
          <Stack flexDirection={{
        md: "row"
      }} alignItems={{
        xs: "center",
        md: "flex-start"
      }} gap={4}>
            {iconBlock.map((block, i) => <IconBlockRegular {...block} key={i} />)}
          </Stack>
        </Container>}
      {layout === "two columns" &&
    //TODO: maybe we should use Grid for this
    <Container sx={{
      display: "flex",
      flexDirection: {
        md: "row",
        xs: "column"
      }
    }}>
          <Box sx={{
        width: {
          lg: "80%",
          md: "100%"
        },
        paddingLeft: {
          lg: "80px",
          md: "40px",
          sm: "40px"
        },
        paddingRight: {
          lg: "80px",
          md: "40px",
          sm: "40px"
        },
        marginRight: {
          lg: "50px",
          md: "0px",
          sm: "0px",
          xs: "0px"
        },
        marginBottom: "16px"
      }}>
            {title && <TitleBig text={title} sx={{
          color: "black",
          align: "left",
          textAlign: "left"
        }} />}
            {description && <RegularText sx={{
          marginTop: {
            lg: "30px",
            xs: "8px"
          },
          marginBottom: {
            lg: "60px",
            xs: "8px"
          }
        }}>
                <Markdown content={description} />
              </RegularText>}
          </Box>
          <Container sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: {
          xs: "0px"
        },
        paddingLeft: {
          xs: "0px"
        }
      }}>
            <Box sx={{
          paddingRight: {
            lg: "0",
            md: "55px",
            sm: "55px",
            xs: "0px"
          },
          paddingLeft: {
            lg: "0",
            md: "55px",
            sm: "55px",
            xs: "0px"
          }
        }} display="flex" justifyContent={"center"}>
              <RegularText sx={{
            paddingBottom: 2
          }}>
                <Markdown content={iconBlockTitle} />
              </RegularText>
              {/*   <Grid container rowSpacing={3}> */}
              <Stack flexDirection="row" justifyContent="center" gap={4}>
                {iconBlock.map((block, i) => <IconBlockRegular {...block} key={i} />)}
              </Stack>
              {/*  </Grid> */}
            </Box>
          </Container>
        </Container>}
    </Box>;
}