export namespace Slack {
  const _sanitizeChannel = (channel?: string) => {
    if (!channel)
      throw Error("env var 'SLACK_CHANNEL_FOR_NOTIFICATIONS' is not set.");
    if (channel.startsWith("#")) return channel;
    else return `#${channel}`;
  };

  const _getIconEmoji = (success?: boolean) => {
    if (success !== undefined) {
      return success ? ":white_check_mark:" : ":x:";
    }
    return ":ghost:";
  };

  export const notify = async (
    message: string,
    jobId: string,
    data: any,
    success?: boolean
  ) => {
    try {
      const channel = process.env.NEXT_PUBLIC_SLACK_CHANNEL_FOR_NOTIFICATIONS;
      const slackWebhookUrl = process.env.NEXT_PUBLIC_SLACK_WEBHOOK_URL;
      if (!slackWebhookUrl)
        throw Error("env var 'SLACK_WEBHOOK_URL' is not set.");

      const payload = {
        channel: _sanitizeChannel(channel),
        username: "Acerta",
        text: `MESSAGE: ${message}, JOB-ID: ${jobId}, DATA: ${JSON.stringify(
          data
        )}`,
        icon_emoji: _getIconEmoji(success),
      };
      const response = await fetch(slackWebhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `payload=${encodeURIComponent(JSON.stringify(payload))}`,
      });

      return response;
    } catch (e) {
      throw new Error(
        `Notify to slack channel function failed with the following error: ${e}`
      );
    }
  };
}
