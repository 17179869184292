import { colors } from "@components/Theme/colors";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Chip } from "@mui/material";
import { clusterToTitle } from "./SelectedChips.util";
interface SelectedChipsProps {
  selectedClusters: string[];
  selectedLocations: string[];
  selectedTypes: string[];
  selectedCategories: string[];
  locale: "fr" | "nl";
  handleRemoveChip: (value: string, category: string) => void;
}
const getLabel = (option: string, locale: "fr-FR" | "fr" | "nl") => {
  const sanitizedOption = option.replace(/-/g, " ").replace(/cluster/g, " ").trim();
  const translations = clusterToTitle[locale];
  if (translations) {
    const translated = translations.get(sanitizedOption);
    if (translated) {
      const firstLetter = translated.charAt(0).toUpperCase();
      return `${firstLetter}${translated.slice(1)}`;
    }
  }
  return sanitizedOption.slice(0, 1).toUpperCase() + sanitizedOption.slice(1);
};
const SelectedChips = ({
  selectedClusters,
  selectedLocations,
  selectedTypes,
  selectedCategories,
  locale,
  handleRemoveChip
}: SelectedChipsProps) => {
  const renderChips = (selectedOptions: string[], category: string) => selectedOptions.map(option => <Chip key={option} label={getLabel(option, locale)} onDelete={() => handleRemoveChip(option, category)} sx={{
    py: 3,
    px: 2,
    borderRadius: 0,
    backgroundColor: colors.secondary.main,
    color: colors.white.main,
    "& .MuiChip-deleteIcon": {
      color: colors.white.main
    }
  }} deleteIcon={<ClearIcon />} />);
  return <Box sx={{
    display: "flex",
    flexWrap: "wrap",
    gap: 1
  }} data-sentry-element="Box" data-sentry-component="SelectedChips" data-sentry-source-file="SelectedChips.tsx">
      {renderChips(selectedClusters, "cluster")}
      {renderChips(selectedLocations, "location")}
      {renderChips(selectedTypes, "type")}
      {renderChips(selectedCategories, "categories")}
    </Box>;
};
export default SelectedChips;