import { Box } from "@mui/material";
import { Ref, useCallback, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Map as MapBoxMap, MapRef, Marker, NavigationControl, Popup } from "react-map-gl";
import { APITypes } from "utils/api.types";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapStyles } from "./mapStyles";
import NextImage from "next/image";
import { LocationCard } from "./LocationCard";
function Fallback({
  error,
  resetErrorBoundary
}: any) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return <div role="alert" data-sentry-component="Fallback" data-sentry-source-file="Map.tsx">
      <p>Something went wrong:</p>
      <pre style={{
      color: "red"
    }}>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>;
}
interface MapProps {
  locations: APITypes.Components.ILocationMapBlock[];
}
export function Map(props: MapProps) {
  const {
    locations
  } = props;
  const mapRef = useRef<MapRef>();
  const [popupOpen, setPopupOpen] = useState<any>({});
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const offsetTop = 0;
  const offsetBottom = -60;
  const offsetLeft = 40;
  const markerOffsetObject = {
    top: [0, offsetTop],
    bottom: [0, offsetBottom],
    left: [offsetLeft, offsetBottom],
    right: [-offsetLeft, offsetBottom]
  };
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlPressed(true);
      }
    }
    function handleKeyUp(event: KeyboardEvent) {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlPressed(false);
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  const onSelectCity = useCallback(({
    longitude,
    latitude
  }: APITypes.Components.ILocationMapBlock) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 1000
    });
  }, []);
  return <Box sx={mapStyles.boxWrapper} data-sentry-element="Box" data-sentry-component="Map" data-sentry-source-file="Map.tsx">
      <ErrorBoundary FallbackComponent={Fallback} onError={error => console.error("An error was caught while rendering the map: ", error)} data-sentry-element="ErrorBoundary" data-sentry-source-file="Map.tsx">
        <MapBoxMap ref={mapRef as Ref<MapRef>} scrollZoom={ctrlPressed} style={mapStyles.mapBoxMap} initialViewState={{
        longitude: 4.709938,
        latitude: 50.883887,
        zoom: 12
      }} mapStyle="mapbox://styles/mapbox/light-v11" mapboxAccessToken={process.env.NEXT_PUBLIC_REACT_APP_MAPBOX_TOKEN} data-sentry-element="MapBoxMap" data-sentry-source-file="Map.tsx">
          <NavigationControl showZoom={true} showCompass={false} data-sentry-element="NavigationControl" data-sentry-source-file="Map.tsx" />
          {(locations || []).map((location, i) => {
          return <Box key={i}>
                <Marker longitude={location.longitude} latitude={location.latitude} anchor="bottom">
                  <div style={{
                cursor: "pointer"
              }} onClick={e => {
                e.stopPropagation();
                setPopupOpen({
                  [location.id]: true,
                  [location.id]: true
                });
                onSelectCity(location);
              }}>
                    <NextImage src="/assets/icons/map-marker.svg" alt="Map marker" width={40} height={50} />
                  </div>
                </Marker>
                {popupOpen[location.id] && <Popup anchor="bottom" maxWidth="fit-content" longitude={location.longitude} latitude={location.latitude} onClose={() => setPopupOpen({
              ...popupOpen,
              [location.id]: false
            })} closeButton={true} offset={markerOffsetObject as any}>
                    <LocationCard location={location} isRichText={true} />
                  </Popup>}
              </Box>;
        })}
        </MapBoxMap>
      </ErrorBoundary>
    </Box>;
}