import { colors } from "@components/Theme/colors";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Image } from "components";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
import { Markdown } from "@components/Markdown/MarkdownComponent";
import { RotatingTitle } from "./RotatingTitle";
export interface RotatingTextBlockButtonProps {
  block: APITypes.Components.ContentBlocks.RotatingTitleBlockWithButton;
}
const RotatingTextBlockButton = ({
  block
}: RotatingTextBlockButtonProps) => {
  const {
    textColor,
    titleFixedPart,
    titleRotatingParts,
    description,
    button,
    image,
    subTitle
  } = block;
  return <Box sx={{
    backgroundColor: colors.white.light
  }} py={14} data-sentry-element="Box" data-sentry-component="RotatingTextBlockButton" data-sentry-source-file="RotatingTextBlockButton.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="RotatingTextBlockButton.tsx">
        <Stack flexDirection={{
        xs: "column",
        md: "row"
      }} justifyContent={"center"} alignItems={"center"} gap={4} data-sentry-element="Stack" data-sentry-source-file="RotatingTextBlockButton.tsx">
          <Stack sx={{
          flex: 1,
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="RotatingTextBlockButton.tsx">
            <Image image={image} alt={image.name} layout="responsive" data-sentry-element="Image" data-sentry-source-file="RotatingTextBlockButton.tsx" />
          </Stack>
          <Stack sx={{
          flex: 1,
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="RotatingTextBlockButton.tsx">
            <Typography data-sentry-element="Typography" data-sentry-source-file="RotatingTextBlockButton.tsx">{subTitle}</Typography>
            <RotatingTitle fixedTitle={titleFixedPart} rotatingTitleParts={titleRotatingParts.map(rp => ({
            text: rp.rotatingTitle,
            color: rp.textColor
          }))} data-sentry-element="RotatingTitle" data-sentry-source-file="RotatingTextBlockButton.tsx" />
            <Typography component={"div"} data-sentry-element="Typography" data-sentry-source-file="RotatingTextBlockButton.tsx">
              <Markdown content={description} data-sentry-element="Markdown" data-sentry-source-file="RotatingTextBlockButton.tsx" />
            </Typography>
            {button && <Stack flexDirection={"row"}>
                <Button variant="contained" size="large">
                  <NextLink style={{
                textDecoration: "none",
                color: "#fff"
              }} href={button.href}>
                    {button.title}
                  </NextLink>
                </Button>
              </Stack>}
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default RotatingTextBlockButton;