import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import ContactForm from "./ContactForm";
export interface ContactITClusterProps {
  block: APITypes.Components.ContentBlocks.ContactITCluster;
}
const ContactITCluster = ({
  block
}: ContactITClusterProps) => {
  const {
    formDescription,
    form
  } = block;
  return <Box id="form" sx={{
    backgroundColor: colors.greyColor.light
  }} py={{
    xs: 8,
    lg: 14
  }} data-sentry-element="Box" data-sentry-component="ContactITCluster" data-sentry-source-file="ContactITCluster.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="ContactITCluster.tsx">
        <Stack sx={{
        width: {
          xs: "100%",
          lg: "50%"
        }
      }} gap={4} data-sentry-element="Stack" data-sentry-source-file="ContactITCluster.tsx">
          <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="ContactITCluster.tsx">{formDescription.title}</Typography>
          <Typography component={"div"} data-sentry-element="Typography" data-sentry-source-file="ContactITCluster.tsx">
            <Markdown content={formDescription.description} data-sentry-element="Markdown" data-sentry-source-file="ContactITCluster.tsx" />
          </Typography>
        </Stack>
        <Grid item xs={12} md={6} mt={2} data-sentry-element="Grid" data-sentry-source-file="ContactITCluster.tsx">
          <ContactForm form={form} data-sentry-element="ContactForm" data-sentry-source-file="ContactITCluster.tsx" />
        </Grid>
      </Container>
    </Box>;
};
export default ContactITCluster;