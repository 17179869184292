import { Markdown } from "@components/Markdown/MarkdownComponent";
import { Box, Container, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import IconBlockAdaptable from "./IconBlockAdaptable";
export interface InfoIconBlocksProps {
  block: APITypes.Components.ContentBlocks.InfoIconBlocks;
}
const InfoIconBlocks = ({
  block
}: InfoIconBlocksProps) => {
  const {
    direction,
    textBlock,
    iconBlock,
    alignment
  } = block;
  return <Box py={14} data-sentry-element="Box" data-sentry-component="InfoIconBlocks" data-sentry-source-file="InfoIconBlocks.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="InfoIconBlocks.tsx">
        <Stack flexDirection={"column"} justifyContent={"center"} alignItems={"center"} width={"100%"} gap={{
        xs: 4,
        lg: 8
      }} data-sentry-element="Stack" data-sentry-source-file="InfoIconBlocks.tsx">
          <Stack maxWidth={{
          xs: "100%",
          lg: "60%"
        }} data-sentry-element="Stack" data-sentry-source-file="InfoIconBlocks.tsx">
            <Typography component={"div"} align="center" variant="h2" sx={{
            "& p": {
              margin: "0"
            }
          }} data-sentry-element="Typography" data-sentry-source-file="InfoIconBlocks.tsx">
              <Markdown content={textBlock.title} data-sentry-element="Markdown" data-sentry-source-file="InfoIconBlocks.tsx" />
            </Typography>
            <Typography component={"div"} sx={{
            textAlign: "center"
          }} data-sentry-element="Typography" data-sentry-source-file="InfoIconBlocks.tsx">
              <Markdown content={textBlock.description} data-sentry-element="Markdown" data-sentry-source-file="InfoIconBlocks.tsx" />
            </Typography>
          </Stack>
          <Stack sx={{
          justifyContent: "center",
          alignItems: {
            xs: "center",
            lg: "start"
          },
          display: "flex",
          flexDirection: {
            lg: "row",
            xs: "column"
          },
          flexWrap: "wrap"
        }} data-sentry-element="Stack" data-sentry-source-file="InfoIconBlocks.tsx">
            {iconBlock.map((block, i) => <IconBlockAdaptable key={i} alignment={alignment} direction={direction} amountOfBlocks={iconBlock.length} description={block.description} image={block.image} title={block.title} />)}
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default InfoIconBlocks;