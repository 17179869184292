import { colors } from "@components/Theme/colors";

export const locationCardStyles = {
  card: {
    width: "380px",
    maxWidth: "380px",
    maxHeight: "900px",
    height: "fit-content",
    boxShadow: "none",
    borderRadius: 0,
    p: "20px",
  },
  button: {
    width: "fit-content",
    backgroundColor: "#D9D9D9",
    color: colors.primary.main,
    fontSize: "0.875rem",
    fontWeight: 400,
  },
};
