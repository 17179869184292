import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import TitleBig from "@components/typography/TitleBig";
import { Box, CardContent, Container, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
export interface IColoredTextBlockProps {
  block: APITypes.Components.ContentBlocks.ColoredTextBlock;
}
const getPosition = (textBlockPosition: "left" | "center" | "right") => {
  switch (textBlockPosition) {
    case "left":
      return "start";
    case "center":
      return "center";
    case "right":
      return "end";
  }
};
const ColoredTextBlock = ({
  block
}: IColoredTextBlockProps) => {
  const {
    title,
    backgroundColor,
    textBlockPosition,
    content,
    textColor = "#000000",
    subTitle
  } = block;
  const isCenter = textBlockPosition === "center";
  return <Box sx={{
    py: "48px",
    backgroundColor: backgroundColor
  }} data-sentry-element="Box" data-sentry-component="ColoredTextBlock" data-sentry-source-file="ColoredTextBlock.tsx">
      <Box sx={{
      display: "flex",
      justifyContent: "center"
    }} data-sentry-element="Box" data-sentry-source-file="ColoredTextBlock.tsx">
        <Container sx={{
        display: "flex",
        justifyContent: {
          lg: getPosition(textBlockPosition),
          sm: getPosition(textBlockPosition),
          xs: "center"
        },
        marginBottom: "16px"
      }} data-sentry-element="Container" data-sentry-source-file="ColoredTextBlock.tsx">
          <Stack sx={{
          width: {
            xs: "100%",
            lg: isCenter ? "80%" : "60%"
          },
          background: backgroundColor
        }} data-sentry-element="Stack" data-sentry-source-file="ColoredTextBlock.tsx">
            <Box sx={{
            margin: {
              lg: 6,
              xs: 2
            }
          }} data-sentry-element="Box" data-sentry-source-file="ColoredTextBlock.tsx">
              <CardContent data-sentry-element="CardContent" data-sentry-source-file="ColoredTextBlock.tsx">
                <Stack flexDirection={{
                xs: "column",
                md: isCenter ? "row" : "column"
              }} gap={{
                xs: 2,
                md: isCenter ? 4 : 2
              }} data-sentry-element="Stack" data-sentry-source-file="ColoredTextBlock.tsx">
                  <Stack alignItems={{
                  xs: "start",
                  md: "end"
                }} gap={4} data-sentry-element="Stack" data-sentry-source-file="ColoredTextBlock.tsx">
                    <Typography variant="h2" sx={{
                    fontSize: {
                      xs: "1.5rem",
                      md: "2.188rem"
                    },
                    px: 2,
                    mt: -4,
                    backgroundColor: colors.green.main
                  }} data-sentry-element="Typography" data-sentry-source-file="ColoredTextBlock.tsx">
                      {subTitle}
                    </Typography>
                    <TitleBig text={title} style={{
                    color: textColor
                  }} sx={{
                    "& p": {
                      m: 0
                    },
                    textAlign: {
                      xs: "left",
                      lg: isCenter ? "right" : "left"
                    }
                  }} data-sentry-element="TitleBig" data-sentry-source-file="ColoredTextBlock.tsx" />
                  </Stack>
                  <Stack data-sentry-element="Stack" data-sentry-source-file="ColoredTextBlock.tsx">
                    {content && <RegularText sx={{
                    mt: {
                      xs: 0,
                      md: isCenter ? 12 : 0
                    },
                    color: textColor,
                    "& p": {
                      m: 0
                    },
                    "& a": {
                      color: colors.primary.main,
                      textDecoration: "none",
                      backgroundColor: isCenter ? "#E0E0E0" : colors.green.main,
                      p: "16px"
                    }
                  }}>
                        <Markdown content={content} />
                      </RegularText>}
                  </Stack>
                </Stack>
              </CardContent>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>;
};
export default ColoredTextBlock;