import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import TitleBig from "@components/typography/TitleBig";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Card, CardContent, Container, Stack } from "@mui/material";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
import { getBaseUrl } from "utils/fetchingFunctions";
export interface IImageWithTextBlockProps {
  block: APITypes.Components.ContentBlocks.ImageWithTextBlock;
}
const getPosition = (textBlockPosition: "left" | "center" | "right") => {
  switch (textBlockPosition) {
    case "left":
      return "start";
    case "center":
      return "center";
    case "right":
      return "end";
  }
};
const ImageWithTextBlock = ({
  block
}: IImageWithTextBlockProps) => {
  const {
    backgroundImage,
    title,
    backgroundColor,
    textBlockPosition,
    content,
    textblockBackgroundColor,
    textColor = "#000000",
    buttons
  } = block;
  return <Container disableGutters sx={{
    position: "relative"
  }} data-sentry-element="Container" data-sentry-component="ImageWithTextBlock" data-sentry-source-file="ImageWithTextBlock.tsx">
      <Box sx={{
      backgroundColor: backgroundColor ?? "#F3F3F3",
      paddingBottom: `${backgroundImage.height / backgroundImage.width * 100}%`,
      maxHeight: "100%",
      maxWidth: "100%",
      backgroundImage: {
        xs: `url(${getBaseUrl().base}${backgroundImage.url})`,
        md: `url(${getBaseUrl().base}${backgroundImage.url})`
      },
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain"
    }} data-sentry-element="Box" data-sentry-source-file="ImageWithTextBlock.tsx" />
      <Box sx={{
      display: "flex",
      justifyContent: "center",
      position: {
        md: "absolute",
        xs: "relative"
      },
      bottom: 0,
      width: "100%"
    }} data-sentry-element="Box" data-sentry-source-file="ImageWithTextBlock.tsx">
        <Container disableGutters sx={{
        marginRight: {
          xs: 0,
          md: 2
        },
        marginLeft: {
          xs: 0,
          md: 2
        },
        display: "flex",
        justifyContent: {
          md: getPosition(textBlockPosition),
          sm: "center"
        }
      }} data-sentry-element="Container" data-sentry-source-file="ImageWithTextBlock.tsx">
          <Card sx={{
          width: {
            lg: "50%",
            md: "60%",
            sm: "100%"
          },
          background: colors.secondary.main
        }} data-sentry-element="Card" data-sentry-source-file="ImageWithTextBlock.tsx">
            <Box sx={{
            padding: {
              lg: 4,
              xs: 2
            }
          }} data-sentry-element="Box" data-sentry-source-file="ImageWithTextBlock.tsx">
              <CardContent sx={{
              color: colors.white.main
            }} data-sentry-element="CardContent" data-sentry-source-file="ImageWithTextBlock.tsx">
                <TitleBig text={title} style={{
                color: colors.white.main
              }} data-sentry-element="TitleBig" data-sentry-source-file="ImageWithTextBlock.tsx" />
                {content && <RegularText sx={{
                "& a": {
                  color: "white",
                  textDecoration: "none"
                }
              }}>
                    <Markdown content={content} />
                  </RegularText>}
                <Stack gap={2} data-sentry-element="Stack" data-sentry-source-file="ImageWithTextBlock.tsx">
                  {buttons?.map((button, index) => <Button component={NextLink} target="_blank" sx={{
                  width: "fit-content"
                }} key={index} href={button.href} variant="contained" color="green" endIcon={<ArrowForwardIcon />}>
                      {button.title}
                    </Button>)}
                </Stack>
              </CardContent>
            </Box>
          </Card>
        </Container>
      </Box>
    </Container>;
};
export default ImageWithTextBlock;