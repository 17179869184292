import LandingPageHero from "@components/blocks/LandingPageHero/LandingPageHero";
import { APITypes } from "utils/api.types";
export interface HeroProps {
  block: APITypes.Components.ContentBlocks.Hero;
}
const Hero = ({
  block
}: HeroProps) => {
  const {
    backgroundImage,
    title
  } = block;
  return <LandingPageHero block={{
    ...block,
    heroImage: backgroundImage,
    heroSquareBlock: {
      title: title
    }
  } as any} data-sentry-element="LandingPageHero" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx" />;
};
export { Hero };