import { BreadCrumbs } from "@components/BreadCrumbs/BreadCrumbs";
import { Markdown } from "@components/Markdown/MarkdownComponent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Card, CardActions, CardContent, Container, Grid, Pagination, Typography } from "@mui/material";
import { Image } from "components";
import NextLink from "next/link";
import { ChangeEvent, useState } from "react";
import { APITypes } from "utils/api.types";
export interface NewsPageBlockProps {
  block: APITypes.Components.ContentBlocks.NewsPageBlock;
}
const NewsPageBlock = ({
  block
}: NewsPageBlockProps) => {
  const {
    newsPages
  } = block;
  const [page, setPage] = useState(1);
  const [cardsPerPage] = useState(6);
  const startIndex = (page - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const cardsToDisplay = block.newsPages.slice(startIndex, endIndex);
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return <Box py={"32px"} sx={{
    backgroundColor: "#F3F3F3"
  }} data-sentry-element="Box" data-sentry-component="NewsPageBlock" data-sentry-source-file="NewsPageBlock.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="NewsPageBlock.tsx">
        <Box sx={{
        mb: "32px"
      }} data-sentry-element="Box" data-sentry-source-file="NewsPageBlock.tsx">
          <BreadCrumbs data-sentry-element="BreadCrumbs" data-sentry-source-file="NewsPageBlock.tsx" />
        </Box>
        <Grid container spacing={4} sx={{
        display: "flex",
        flexDirection: {
          md: "row",
          xs: "column"
        },
        justifyContent: "center",
        alignItems: {
          xs: "center",
          md: "stretch"
        }
      }} data-sentry-element="Grid" data-sentry-source-file="NewsPageBlock.tsx">
          {cardsToDisplay.map((news, i) => {
          return <Grid item lg={4} md={4} sm={12} xs={12} key={i}>
                <PreviewNewsCard news={news} isRichText={true} />
              </Grid>;
        })}
          <Grid item xs={12} sx={{
          display: "flex",
          justifyContent: "center"
        }} data-sentry-element="Grid" data-sentry-source-file="NewsPageBlock.tsx">
            <Pagination count={newsPages.length / 6} page={page} onChange={handleChange} data-sentry-element="Pagination" data-sentry-source-file="NewsPageBlock.tsx" />
          </Grid>
        </Grid>
      </Container>
    </Box>;
};
export default NewsPageBlock;
interface PreviewNewsCardProps {
  news: {
    title: string;
    description: string;
    image: APITypes.Components.Media;
    slug: string;
  };
  isRichText: boolean;
}
const PreviewNewsCard = ({
  news,
  isRichText
}: PreviewNewsCardProps) => {
  return <NextLink style={{
    height: "100%",
    fontSize: "18px",
    fontWeight: 700,
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    flex: 1
  }} href={news.slug} data-sentry-element="NextLink" data-sentry-component="PreviewNewsCard" data-sentry-source-file="NewsPageBlock.tsx">
      <Card sx={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      maxWidth: "455px",
      boxShadow: "none",
      borderRadius: 0
    }} data-sentry-element="Card" data-sentry-source-file="NewsPageBlock.tsx">
        <Image alt={news.image?.name} image={news.image} style={{
        minHeight: "247px",
        maxHeight: "247px",
        maxWidth: "455px"
      }} layout="responsive" data-sentry-element="Image" data-sentry-source-file="NewsPageBlock.tsx" />
        <CardContent data-sentry-element="CardContent" data-sentry-source-file="NewsPageBlock.tsx">
          <Typography sx={{
          fontSize: "27px",
          fontWeight: 700,
          lineHeight: "100%"
        }} data-sentry-element="Typography" data-sentry-source-file="NewsPageBlock.tsx">
            {news.title}
          </Typography>
          <Typography component={"div"} sx={{
          marginTop: 2,
          fontSize: "18px",
          fontWeight: 400
        }} data-sentry-element="Typography" data-sentry-source-file="NewsPageBlock.tsx">
            {isRichText ? <Markdown content={news.description} /> : news.description}
          </Typography>
        </CardContent>
        <CardActions sx={{
        mt: "auto"
      }} data-sentry-element="CardActions" data-sentry-source-file="NewsPageBlock.tsx">
          <Box sx={{
          color: "#54B8E3",
          paddingY: {
            xs: 8,
            md: 0
          },
          display: "flex",
          justifyContent: "flex-start"
        }} data-sentry-element="Box" data-sentry-source-file="NewsPageBlock.tsx">
            <ArrowForwardIcon data-sentry-element="ArrowForwardIcon" data-sentry-source-file="NewsPageBlock.tsx" />
          </Box>
        </CardActions>
      </Card>
    </NextLink>;
};