import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import { Image } from "components";
import { APITypes } from "utils/api.types";
export interface QuoteBlockProps {
  block: APITypes.Components.ContentBlocks.QuoteBlock;
}
const QuoteBlock = ({
  block
}: QuoteBlockProps) => {
  const {
    author,
    quote,
    hexTextColor,
    hexBackgroundColor,
    backgroundImage,
    position
  } = block;
  return <Box py={20} sx={{
    backgroundColor: colors.white.main
  }} data-sentry-element="Box" data-sentry-component="QuoteBlock" data-sentry-source-file="QuoteBlock.tsx">
      {backgroundImage && <Box>
          <Image image={backgroundImage} alt={backgroundImage?.name} layout={"responsive"} />
        </Box>}
      <Container sx={{
      mt: backgroundImage ? -10 : 0
    }} data-sentry-element="Container" data-sentry-source-file="QuoteBlock.tsx">
        <Stack flexDirection={{
        xs: "column",
        md: "row"
      }} justifyContent={"center"} alignItems={"center"} gap={8} data-sentry-element="Stack" data-sentry-source-file="QuoteBlock.tsx">
          <Stack sx={{
          flex: 1,
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="QuoteBlock.tsx">
            <Typography component={"div"} variant="h2" sx={{
            textAlign: {
              xs: "center",
              lg: "right"
            }
          }} data-sentry-element="Typography" data-sentry-source-file="QuoteBlock.tsx">
              <Markdown content={author} data-sentry-element="Markdown" data-sentry-source-file="QuoteBlock.tsx" />
            </Typography>
          </Stack>
          <Stack sx={{
          flex: 1,
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="QuoteBlock.tsx">
            <Paper sx={{
            backgroundColor: colors.greyColor.light,
            color: colors.primary.main,
            padding: "40px",
            border: "none",
            boxShadow: "none",
            display: "flex"
          }} data-sentry-element="Paper" data-sentry-source-file="QuoteBlock.tsx">
              <Stack gap={4} data-sentry-element="Stack" data-sentry-source-file="QuoteBlock.tsx">
                <Typography align="center" variant="body1" sx={{
                "& .quotes": {
                  color: colors.pink.main
                },
                fontSize: "1.5rem"
              }} data-sentry-element="Typography" data-sentry-source-file="QuoteBlock.tsx">
                  <span className="quotes">&quot;</span>
                  {quote}
                  <span className="quotes">&quot;</span>
                </Typography>
                <Typography align="center" component={"div"} sx={{
                color: colors.pink.main
              }} data-sentry-element="Typography" data-sentry-source-file="QuoteBlock.tsx">
                  <Markdown content={`── ${position}`} data-sentry-element="Markdown" data-sentry-source-file="QuoteBlock.tsx" />
                </Typography>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default QuoteBlock;