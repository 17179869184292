import { colors } from "@components/Theme/colors";
import theme from "@components/Theme/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/skyblue";
import { useRouter } from "next/router";
import { useRef } from "react";
import { APITypes } from "utils/api.types";
import QuoteWithPicture from "./QuoteWithPicture";
export interface IQuotesCarousel {
  block: APITypes.Components.ContentBlocks.QuotesCarousel;
}
export default function QuotesCarousel({
  block
}: IQuotesCarousel) {
  const {
    locale
  } = useRouter();
  const mainRef = useRef<Splide>(null);
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.down("md"));
  const handleBack = () => {
    if (mainRef.current) {
      mainRef.current.go("<");
    }
  };
  const handleNext = () => {
    if (mainRef.current) {
      mainRef.current.go(">");
    }
  };
  const slidesPerPage = isExtraSmallSize ? 1 : 3;
  return <Box sx={{
    backgroundColor: colors.greyColor.main,
    py: 14
  }} data-sentry-element="Box" data-sentry-component="QuotesCarousel" data-sentry-source-file="QuotesCarousel.tsx">
      <Container sx={{
      "& .splide": {
        padding: 0
      },
      "& .splide__slide": {
        opacity: 0.5
        // transition: "transform .1s",
      },
      "& .splide__slide.is-visible.is-active": {
        opacity: 1
        // transform: " scale(1.1)",
      }
    }} data-sentry-element="Container" data-sentry-source-file="QuotesCarousel.tsx">
        <Stack flexDirection={{
        xs: "column",
        lg: "row"
      }} alignItems={"center"} justifyContent={"space-between"} data-sentry-element="Stack" data-sentry-source-file="QuotesCarousel.tsx">
          <Typography variant="h2" sx={{
          maxWidth: {
            xs: "100%",
            md: "40%"
          }
        }} data-sentry-element="Typography" data-sentry-source-file="QuotesCarousel.tsx">
            {block?.title}
          </Typography>
          <Stack flexDirection={"row"} gap={2} sx={{
          height: "fit-content"
        }} data-sentry-element="Stack" data-sentry-source-file="QuotesCarousel.tsx">
            <Button sx={{
            px: 1,
            py: 0.5
          }} variant="contained" onClick={handleBack} data-sentry-element="Button" data-sentry-source-file="QuotesCarousel.tsx">
              <ArrowBackIcon sx={{
              p: 0,
              m: 0,
              width: "20px"
            }} fontSize="small" data-sentry-element="ArrowBackIcon" data-sentry-source-file="QuotesCarousel.tsx" />
            </Button>
            <Button sx={{
            px: 1,
            py: 0.5
          }} onClick={handleNext} variant="contained" data-sentry-element="Button" data-sentry-source-file="QuotesCarousel.tsx">
              <ArrowForwardIcon sx={{
              p: 0,
              m: 0,
              width: "20px"
            }} fontSize="small" data-sentry-element="ArrowForwardIcon" data-sentry-source-file="QuotesCarousel.tsx" />
            </Button>
          </Stack>
        </Stack>
        <Splide ref={mainRef} style={{
        width: "100%",
        display: "flex"
      }} options={{
        type: "loop",
        perPage: slidesPerPage,
        focus: "center",
        arrows: false,
        pagination: false
      }} data-sentry-element="Splide" data-sentry-source-file="QuotesCarousel.tsx">
          {block.quotes?.map((quote, i) => <SplideSlide key={`${locale}/${i}`} style={{
          display: "flex",
          justifyContent: "center"
        }}>
              <QuoteWithPicture block={quote} />
            </SplideSlide>)}
        </Splide>
      </Container>
    </Box>;
}