import { colors } from "@components/Theme/colors";

export const mabBlockStyles = {
  stackContainer: {
    pt: "120px",
    pb: "160px",
    backgroundColor: colors.greyColor.light,
  },
  divider: {
    borderColor: colors.black.main,
  },
  link: {
    color: colors.secondary.main,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  listText: {
    fontSize: "1.125rem",
    fontWeight: "400",
    color: colors.primary.main,
    m: 0,
  },
};
