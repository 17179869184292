import { colors } from "@components/Theme/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
interface MovingBarProps {
  block?: APITypes.Components.ContentBlocks.MovingBar;
}
const Banner = ({
  block
}: MovingBarProps) => {
  if (!block) {
    return null;
  }
  return <Paper sx={{
    width: "100%",
    position: "fixed",
    bottom: 0,
    boxShadow: "-4px 4px 25px 0px rgba(0, 0, 0, 0.10)",
    height: "80px",
    backgroundColor: colors.primary.main,
    display: "flex"
  }} component="footer" square variant="elevation" data-sentry-element="Paper" data-sentry-component="Banner" data-sentry-source-file="Banner.tsx">
      <Container sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      alignContent: "center"
    }} data-sentry-element="Container" data-sentry-source-file="Banner.tsx">
        <Typography variant="h2" style={{
        color: colors.white.main,
        margin: 0
      }} data-sentry-element="Typography" data-sentry-source-file="Banner.tsx">
          {block.coloredTitle} {block.title}
        </Typography>
        <Button component={NextLink} variant="contained" color="green" href={block.button.href} endIcon={<ArrowForwardIcon />} data-sentry-element="Button" data-sentry-source-file="Banner.tsx">
          {block.button.title}
        </Button>
      </Container>
    </Paper>;
};
export default Banner;