import { Button, FormGroup, Icon, Link, List, ListItem, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Image } from "components";
import { APITypes } from "utils/api.types";
interface SubFooterProps {
  subFooter: APITypes.Components.ContentBlocks.SubFooter;
}
const SubFooter = ({
  subFooter
}: SubFooterProps) => {
  return <Container data-sentry-element="Container" data-sentry-component="SubFooter" data-sentry-source-file="SubFooter.tsx">
      <Stack justifyContent={"space-between"} flexWrap="wrap" sx={{
      py: 4,
      flexDirection: {
        xs: "column",
        md: "row"
      },
      alignItems: {
        xs: "start",
        md: "center"
      }
    }} gap={4} data-sentry-element="Stack" data-sentry-source-file="SubFooter.tsx">
        <Stack sx={{
        maxWidth: "438px"
      }} gap={4} data-sentry-element="Stack" data-sentry-source-file="SubFooter.tsx">
          <Icon sx={{
          width: "196px",
          height: "70px"
        }} data-sentry-element="Icon" data-sentry-source-file="SubFooter.tsx">
            <Image alt={subFooter.logo.name} image={subFooter.logo} style={{
            width: 196,
            height: 70,
            objectFit: "contain"
          }} data-sentry-element="Image" data-sentry-source-file="SubFooter.tsx" />
          </Icon>
          <Typography data-sentry-element="Typography" data-sentry-source-file="SubFooter.tsx">{subFooter.description}</Typography>
        </Stack>
        <Stack gap={8} sx={{
        flexDirection: {
          xs: "column",
          md: "row"
        }
      }} data-sentry-element="Stack" data-sentry-source-file="SubFooter.tsx">
          {subFooter.navigationMenu.map((nav, i) => <Stack key={i} sx={{
          maxWidth: "200px"
        }} gap={2}>
              <Box>
                <Typography variant="h3" style={{
              fontFamily: "Goodall Pro",
              fontWeight: 500,
              fontSize: "1.5rem"
            }}>
                  {nav.title}
                </Typography>
              </Box>

              {nav.links.length > 0 && <List>
                  {nav.links.map((item, i) => item.icon != null ? <Link target="_blank" href={item.href ?? "/"} underline="none" key={i} style={{
              paddingRight: "14px"
            }}>
                        <Image alt={item.icon.name} image={item.icon} style={{
                backgroundColor: "lightgray",
                height: "24px",
                width: "24px",
                padding: "4px"
              }} />
                      </Link> : <ListItem key={i} disableGutters={true}>
                        <Link target="_blank" href={item.href ?? "/"} underline="none" key={i} sx={{
                fontSize: "1rem",
                color: "#000000de"
              }}>
                          {item.name}
                        </Link>
                      </ListItem>)}
                </List>}
              {nav.subTitle && <Typography sx={{
            fontSize: "1rem"
          }}>
                  {nav.subTitle}
                </Typography>}
              {nav.inputName && <Box>
                  <FormGroup row>
                    <TextField variant="outlined" placeholder={nav.inputName} sx={{
                "& fieldset": {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0
                },
                input: {
                  "&::placeholder": {
                    color: "#3EA4DC",
                    fontStyle: "italic"
                  },
                  border: "1px solid #3EA4DC",
                  width: "180px"
                }
              }} />
                    <Button variant="contained" disableElevation sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                textTransform: "lowercase",
                fill: "white",
                backgroundColor: "#3EA4DC"
              }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30">
                        <path d="M.838 15.007C-.319 15.614-.271 16.503.942 16.983L4.536 18.405C5.75 18.887 7.587 18.624 8.616 17.821L24.223 5.513C25.249 4.706 25.359 4.825 24.468 5.779L12.13 18.985C11.236 19.936 11.5 21.104 12.718 21.575L13.139 21.739C14.357 22.21 16.342 23.001 17.552 23.493L21.538 25.116C22.749 25.608 24.043 25.028 24.387 23.768L30.462 1.438C30.805.178 30.139-.357 28.982.249L.838 15.007zM10.701 29.626C10.63 29.839 13.159 25.964 13.159 25.964 13.874 24.872 13.468 23.569 12.26 23.075L9.502 21.946C8.294 21.452 7.715 22.032 8.215 23.239 8.215 23.239 10.774 29.407 10.701 29.626z"></path>
                      </svg>
                    </Button>
                  </FormGroup>
                </Box>}
            </Stack>)}
        </Stack>
      </Stack>
    </Container>;
};
export default SubFooter;