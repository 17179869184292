import { Markdown } from "@components/Markdown/MarkdownComponent";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
import { Image } from "..";
export interface IconBlockWithButtonProps {
  block: APITypes.Components.ContentBlocks.IconBlockWithButton;
}
export default function IconBlockWithButton({
  block
}: IconBlockWithButtonProps) {
  return <Box sx={{
    backgroundColor: "#FFF"
  }} py={{
    xs: 8,
    lg: 20
  }} data-sentry-element="Box" data-sentry-component="IconBlockWithButton" data-sentry-source-file="IconBlockWithButton.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="IconBlockWithButton.tsx">
        <Stack justifyContent="center" alignItems="center" direction="column" gap={8} data-sentry-element="Stack" data-sentry-source-file="IconBlockWithButton.tsx">
          <Typography variant="h2" align={"center"} data-sentry-element="Typography" data-sentry-source-file="IconBlockWithButton.tsx">
            {block.title}
          </Typography>
          <Stack flexDirection={{
          sm: "column",
          md: "row"
        }} flexWrap="wrap" width="100%" justifyContent="center" gap={3} data-sentry-element="Stack" data-sentry-source-file="IconBlockWithButton.tsx">
            {block.iconBlock.map((block, i) => {
            return <Stack key={i} flexDirection="row" alignItems="start" gap={2} sx={{
              width: {
                sm: "unset",
                md: "280px"
              }
            }}>
                  <Image style={{
                maxWidth: "40px",
                maxHeight: "40px"
              }} image={block.image} alt={block.image?.name} />
                  <Stack>
                    <Typography component={"div"} variant="h3" sx={{
                  "& p": {
                    margin: 0
                  }
                }}>
                      <Markdown content={block.title} />
                    </Typography>
                    <Typography component={"div"}>
                      <Markdown content={block.description} />
                    </Typography>
                  </Stack>
                </Stack>;
          })}
          </Stack>
          <Stack data-sentry-element="Stack" data-sentry-source-file="IconBlockWithButton.tsx">
            <Button variant="contained" component={NextLink} href={`${block.button.href}`} data-sentry-element="Button" data-sentry-source-file="IconBlockWithButton.tsx">
              {block.button.title}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>;
}