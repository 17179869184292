import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";
export interface CollapsibleListProps {
  block: APITypes.Components.ContentBlocks.CollapsibleList;
  blocksExpandedByDefault?: boolean;
}
const CollapsibleList = ({
  block,
  blocksExpandedByDefault = false
}: CollapsibleListProps) => {
  const {
    list,
    title,
    description
  } = block;
  const [isNotExpended, setIsNotExpended] = useState<{
    [key: number]: boolean;
  }>({});
  return <Stack data-sentry-element="Stack" data-sentry-component="CollapsibleList" data-sentry-source-file="CollapsibleList.tsx">
      <Box sx={{
      backgroundColor: list[0].hexBackgroundColor
    }} py={{
      xs: 6,
      md: 14
    }} data-sentry-element="Box" data-sentry-source-file="CollapsibleList.tsx">
        <Container data-sentry-element="Container" data-sentry-source-file="CollapsibleList.tsx">
          <Stack gap={6} flexDirection={{
          xs: "column",
          md: list.length > 2 ? "column" : "row"
        }} justifyContent={"center"} data-sentry-element="Stack" data-sentry-source-file="CollapsibleList.tsx">
            <Stack sx={{
            flex: 1,
            margin: "auto",
            width: {
              xs: "100%",
              md: "90%"
            }
          }} flexDirection={{
            xs: "column",
            md: description != null ? "row" : "column"
          }} gap={4} data-sentry-element="Stack" data-sentry-source-file="CollapsibleList.tsx">
              <Typography variant="h2" sx={{
              flex: 1,
              textAlign: {
                xs: "left",
                md: list.length > 2 && description == null ? "center" : "right"
              }
            }} data-sentry-element="Typography" data-sentry-source-file="CollapsibleList.tsx">
                {title}
              </Typography>
              {description != null && <Typography component={"div"} sx={{
              mt: {
                xs: 0,
                md: 8
              },
              flex: 2
            }}>
                  <SanitizeString content={description} />
                </Typography>}
            </Stack>
            <Stack sx={{
            flex: 2,
            width: "100%",
            height: "100%"
          }} flexDirection={{
            xs: "column",
            md: "row"
          }} gap={2} alignItems={"stretch"} data-sentry-element="Stack" data-sentry-source-file="CollapsibleList.tsx">
              {list.map((item, i) => <Stack key={i} sx={{
              flex: 1,
              alignItems: "start",
              justifyContent: "start"
            }}>
                  <Accordion defaultExpanded={blocksExpandedByDefault} className="accordion" onChange={(e, isOpen) => setIsNotExpended({
                ...isNotExpended,
                [i]: !isOpen
              })} sx={{
                justifyContent: "start",
                boxShadow: "none",
                height: isNotExpended[i] ? "fit-content" : "100%",
                backgroundColor: colors.greyColor.main
              }}>
                    <AccordionSummary expandIcon={<ExpandLessIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{
                  backgroundColor: colors.greyColor.main,
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    color: colors.white.main,
                    backgroundColor: colors.secondary.main,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    "& .MuiSvgIcon-root": {
                      fontSize: 40
                    }
                  }
                }}>
                      <Typography variant="h5" sx={{
                    color: colors.primary.main,
                    pr: 3
                  }}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component={"div"}>
                        <Markdown content={item.description} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Stack>)}
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Container data-sentry-element="Container" data-sentry-source-file="CollapsibleList.tsx">
        <Divider textAlign="center" data-sentry-element="Divider" data-sentry-source-file="CollapsibleList.tsx" />
      </Container>
    </Stack>;
};
export default CollapsibleList;