import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
import { getBaseUrl } from "utils/fetchingFunctions";
export interface ClustersPreviewProps {
  block: APITypes.Components.ContentBlocks.ClustersPreview;
}
const ClustersPreview = ({
  block
}: ClustersPreviewProps) => {
  const {
    title,
    previewClusterCards
  } = block;
  return <Box py={{
    xs: 8,
    md: 14
  }} sx={{
    backgroundColor: "#FAFAFA"
  }} data-sentry-element="Box" data-sentry-component="ClustersPreview" data-sentry-source-file="ClustersPreview.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="ClustersPreview.tsx">
        <Stack py={4} data-sentry-element="Stack" data-sentry-source-file="ClustersPreview.tsx">
          <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="ClustersPreview.tsx">{title}</Typography>
        </Stack>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2} data-sentry-element="Grid" data-sentry-source-file="ClustersPreview.tsx">
          {previewClusterCards.map((cluster, i) => <PreviewClusterCard key={i} cluster={cluster} />)}
        </Grid>
      </Container>
    </Box>;
};
export default ClustersPreview;
interface PreviewClusterCardProps {
  cluster: APITypes.Components.PreviewClusterCard;
}
const PreviewClusterCard = ({
  cluster
}: PreviewClusterCardProps) => {
  return <Grid container direction={"column"} item xs={12} md={3} component={NextLink} href={cluster.button.href} sx={{
    textDecoration: "none"
  }} data-sentry-element="Grid" data-sentry-component="PreviewClusterCard" data-sentry-source-file="ClustersPreview.tsx">
      <Stack spacing={4} sx={{
      height: "100%",
      backgroundColor: "#FFF"
    }} data-sentry-element="Stack" data-sentry-source-file="ClustersPreview.tsx">
        <Box sx={{
        height: {
          md: "340px",
          xs: "200px"
        },
        backgroundColor: "black",
        backgroundImage: {
          xs: `url(${getBaseUrl().base}${cluster.mobileImage.url})`,
          md: `url(${getBaseUrl().base}${cluster.desktopImage.url})`
        },
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center 10%"
      }} data-sentry-element="Box" data-sentry-source-file="ClustersPreview.tsx" />
        <Stack spacing={2} px={2} sx={{
        height: "100%",
        flex: 1
      }} data-sentry-element="Stack" data-sentry-source-file="ClustersPreview.tsx">
          <Typography variant="h3" data-sentry-element="Typography" data-sentry-source-file="ClustersPreview.tsx">{cluster.title}</Typography>
          <Typography color={"primary"} data-sentry-element="Typography" data-sentry-source-file="ClustersPreview.tsx">{cluster.description}</Typography>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"end"} data-sentry-element="Stack" data-sentry-source-file="ClustersPreview.tsx">
          <Button sx={{
          px: 1,
          py: 0.5
        }} variant="contained" data-sentry-element="Button" data-sentry-source-file="ClustersPreview.tsx">
            <ArrowForwardIcon sx={{
            p: 0,
            m: 0,
            width: "20px"
          }} fontSize="small" data-sentry-element="ArrowForwardIcon" data-sentry-source-file="ClustersPreview.tsx" />
          </Button>
        </Stack>
      </Stack>
    </Grid>;
};