const ClusterToTitleFRMap = new Map([
  ["it", "IT"],
  ["consultants", "Consult"],
  ["payroll", "Payroll"],
  ["self starters", "Starters & Indépendants"],
  ["support", "Services d'assistance"],
]);

const ClusterToTitleNLMap = new Map([
  ["it", "IT"],
  ["consultants", "Consult"],
  ["payroll", "Payroll"],
  ["self starters", "Starters & Zelfstandigen"],
  ["support", "Ondersteunende diensten"],
]);

export const clusterToTitle: Record<
  "fr-FR" | "fr" | "nl",
  Map<string, string>
> = {
  nl: ClusterToTitleNLMap,
  "fr-FR": ClusterToTitleFRMap,
  fr: ClusterToTitleFRMap,
};
