import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import { Stack } from "@mui/material";
import { APITypes } from "utils/api.types";
import { Image } from "..";
export interface IIconBlockRegular {
  image: APITypes.Components.Media;
  description: string;
  leftColumnWidth?: number;
  rightColumnWidth?: number;
}
export default function IconBlockRegular({
  image,
  description
}: IIconBlockRegular) {
  return <Stack flexDirection="row" alignItems="flex-start" gap={3} sx={{
    width: {
      md: "100%",
      lg: "320px"
    },
    padding: 2
  }} data-sentry-element="Stack" data-sentry-component="IconBlockRegular" data-sentry-source-file="IconBlockRegular.tsx">
      <Image style={{
      maxWidth: "40px",
      height: "auto"
    }} image={image} alt={image.alternativeText} data-sentry-element="Image" data-sentry-source-file="IconBlockRegular.tsx" />
      <RegularText sx={{
      "& p": {
        margin: 0,
        padding: 0
      },
      "& strong": {
        fontSize: {
          md: "1.25rem"
        },
        fontWeight: 600,
        color: colors.primary.main,
        display: "block",
        pb: 2
      },
      fontSize: "1rem"
    }} data-sentry-element="RegularText" data-sentry-source-file="IconBlockRegular.tsx">
        <Markdown content={description} data-sentry-element="Markdown" data-sentry-source-file="IconBlockRegular.tsx" />
      </RegularText>
    </Stack>;
}