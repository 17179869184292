import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
export interface TextBlockButtonProps {
  block: APITypes.Components.ContentBlocks.TextBlockButtonComponent;
}
const TextBlockButton = ({
  block
}: TextBlockButtonProps) => {
  const {
    textBlock,
    buttons
  } = block;
  return <Box sx={{
    backgroundColor: colors.greyColor.main
  }} py={14} data-sentry-element="Box" data-sentry-component="TextBlockButton" data-sentry-source-file="TextBlockButton.tsx">
      <Container maxWidth="md" sx={{
      p: {
        md: 0
      }
    }} data-sentry-element="Container" data-sentry-source-file="TextBlockButton.tsx">
        <Stack justifyContent={"center"} alignItems={"center"} gap={6} data-sentry-element="Stack" data-sentry-source-file="TextBlockButton.tsx">
          <Typography variant="h3" align="center" sx={{
          "& p": {
            m: 0
          }
        }} data-sentry-element="Typography" data-sentry-source-file="TextBlockButton.tsx">
            {textBlock.subTitle}
          </Typography>
          <Typography component={"div"} variant="h2" align="center" sx={{
          "& p": {
            m: 0
          }
        }} data-sentry-element="Typography" data-sentry-source-file="TextBlockButton.tsx">
            <Markdown content={textBlock.title} data-sentry-element="Markdown" data-sentry-source-file="TextBlockButton.tsx" />
          </Typography>
          <Typography component={"div"} align="center" sx={{
          "& p": {
            m: 0
          },
          color: colors.primary.main
        }} data-sentry-element="Typography" data-sentry-source-file="TextBlockButton.tsx">
            <Markdown content={textBlock.description} data-sentry-element="Markdown" data-sentry-source-file="TextBlockButton.tsx" />
          </Typography>
          <Stack direction={{
          xs: "column",
          md: "row"
        }} spacing={2} data-sentry-element="Stack" data-sentry-source-file="TextBlockButton.tsx">
            {buttons && buttons.map((currentButton, i) => {
            return <Button component={NextLink} key={i} variant="contained" size="large" color={"green"} href={currentButton.href}>
                    {currentButton.title}
                  </Button>;
          })}
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default TextBlockButton;