import { Markdown } from "@components/Markdown/MarkdownComponent";
import { Box, Container, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";
export interface TextBlockProps {
  block: APITypes.Components.ContentBlocks.TextBlockComponent;
}
const TextBlock = ({
  block
}: TextBlockProps) => {
  const {
    title,
    description,
    hexTextColor,
    hexBackgroundColor
  } = block;
  return <Box sx={{
    backgroundColor: hexBackgroundColor,
    color: hexTextColor
  }} py={{
    xs: 6,
    md: 8
  }} data-sentry-element="Box" data-sentry-component="TextBlock" data-sentry-source-file="TextBlock.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="TextBlock.tsx">
        <Stack flexDirection={{
        xs: "column",
        md: "row"
      }} justifyContent={"center"} alignItems={"start"} gap={{
        xs: 2,
        md: 6
      }} flex={1} data-sentry-element="Stack" data-sentry-source-file="TextBlock.tsx">
          <Stack sx={{
          flex: 1,
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="TextBlock.tsx">
            <Typography component={"div"} variant="h2" sx={{
            textAlign: {
              xs: "left",
              md: "right"
            },
            "& p": {
              margin: "0"
            }
          }} data-sentry-element="Typography" data-sentry-source-file="TextBlock.tsx">
              <SanitizeString content={title} data-sentry-element="SanitizeString" data-sentry-source-file="TextBlock.tsx" />
            </Typography>
          </Stack>
          <Stack flex={3} sx={{
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="TextBlock.tsx">
            <Typography component={"div"} sx={{
            "& p": {
              margin: "0"
            }
          }} data-sentry-element="Typography" data-sentry-source-file="TextBlock.tsx">
              <Markdown content={description} data-sentry-element="Markdown" data-sentry-source-file="TextBlock.tsx" />
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default TextBlock;