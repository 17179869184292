import { Markdown } from "@components/Markdown/MarkdownComponent";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Container, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Image } from "components";
import Carousel from "react-material-ui-carousel";
import { APITypes } from "utils/api.types";
export interface ImageCarouselWithQuoteProps {
  block: APITypes.Components.ContentBlocks.ImageCarouselWithQuote;
}
const ImageCarouselWithQuote = ({
  block
}: ImageCarouselWithQuoteProps) => {
  const {
    title,
    carouselItems
  } = block;
  const theme = useTheme();
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.down("md"));
  return <Box data-sentry-element="Box" data-sentry-component="ImageCarouselWithQuote" data-sentry-source-file="ImageCarouselWithQuote.tsx">
      <Carousel indicators={!isExtraSmallSize} IndicatorIcon={<RemoveIcon sx={{
      fontSize: "120px",
      transform: " scaleY(0.6)"
    }} />} swipe={false} autoPlay={true} sx={{
      minHeight: "720px",
      height: "fit-content"
    }} navButtonsAlwaysInvisible={true} activeIndicatorIconButtonProps={{
      style: {
        color: "#33A3DB"
      }
    }} navButtonsProps={{
      style: {
        background: "#33A3DB"
      }
    }} indicatorIconButtonProps={{
      style: {
        color: "#FFF",
        width: "80px",
        height: "0px"
      }
    }} indicatorContainerProps={{
      style: {
        zIndex: 1,
        marginTop: "-40px",
        position: "absolute"
      }
    }} data-sentry-element="Carousel" data-sentry-source-file="ImageCarouselWithQuote.tsx">
        {carouselItems.map((item, i) => {
        return <Box key={i}>
              <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: {
              xs: "250px",
              sm: "400px",
              md: "794.38px"
            }
          }}>
                <Image image={item.image} alt={item.image.name} style={{
              maxWidth: "1488px",
              width: "100%",
              height: "100%",
              objectFit: "cover"
            }} />
              </Box>
              <Container maxWidth={"lg"}>
                <Box sx={{
              maxWidth: {
                xs: "100vw",
                md: "668px"
              },
              width: {
                xs: "100vw",
                md: "100%"
              },
              position: {
                xs: "relative",
                md: "absolute"
              },
              zIndex: 999,
              top: "20%"
            }}>
                  <Box sx={{
                position: {
                  xs: "absolute",
                  md: "relative"
                },
                zIndex: 999,
                bottom: "100%"
              }}>
                    <Typography variant="h2" component={"h2"} sx={{
                  pr: {
                    xs: "36px",
                    md: "0px"
                  },
                  mb: {
                    xs: "0px",
                    md: "40px"
                  },
                  fontSize: {
                    lg: "54px",
                    xs: "30px"
                  },
                  fontWeight: 700,
                  lineHeight: "100%",
                  color: "#FFF"
                }}>
                      {title}
                    </Typography>
                  </Box>
                  <Box>
                    <div style={{
                  marginTop: "-40px",
                  height: 0,
                  borderTop: "40px solid transparent",
                  borderBottom: "0px solid transparent",
                  borderLeft: "40px solid #fff",
                  top: "0px",
                  left: "80%",
                  position: "relative"
                }} />
                    <Paper sx={{
                  position: "relative",
                  top: "0px",
                  minHeight: "380px",
                  boxShadow: "none",
                  p: {
                    xs: "36px",
                    lg: "40px"
                  },
                  ml: {
                    xs: "-16px",
                    sm: "-24px",
                    md: 0
                  }
                }}>
                      <Typography component={"div"} sx={{
                    "& p": {
                      margin: 0
                    },
                    fontSize: {
                      xs: "22px",
                      lg: "30px"
                    },
                    fontWeight: 700,
                    color: "#54B8E3",
                    linHeight: "130%",
                    letterSpacing: "-0.6px"
                  }}>
                        <Markdown content={item.quote} />
                      </Typography>
                      <Box mt={"35px"} />
                      <Typography variant="body2" component={"div"} sx={{
                    fontSize: {
                      xs: "16px",
                      lg: "18px"
                    },
                    textTransform: "uppercase",
                    fontWeight: 500
                  }}>
                        <Markdown content={item.author} />
                      </Typography>
                    </Paper>
                  </Box>
                </Box>
              </Container>
            </Box>;
      })}
      </Carousel>
    </Box>;
};
export default ImageCarouselWithQuote;