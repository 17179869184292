import { Markdown } from "@components/Markdown/MarkdownComponent";
import { Grid, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import { Image } from "..";
export interface IIconBlockAdaptableProps {
  direction: "row" | "column";
  amountOfBlocks: number;
  image: APITypes.Components.Media;
  title: string;
  description: string;
  //TODO
  alignment: any;
}
export default function IconBlockAdaptable({
  direction,
  amountOfBlocks,
  alignment,
  image,
  title,
  description
}: IIconBlockAdaptableProps) {
  return <Grid sx={{
    display: "flex",
    flexDirection: {
      lg: "row",
      xs: "column"
    },
    pb: {
      xs: 4,
      lg: 8
    }
  }} container item xs={12} lg={4} justifyContent="center" alignItems={alignment} direction={"column"} data-sentry-element="Grid" data-sentry-component="IconBlockAdaptable" data-sentry-source-file="IconBlockAdaptable.tsx">
      <Stack flexDirection={direction} alignItems={direction === "column" ? "center" : "start"} gap={2} data-sentry-element="Stack" data-sentry-source-file="IconBlockAdaptable.tsx">
        <Image image={image} alt={image?.name} data-sentry-element="Image" data-sentry-source-file="IconBlockAdaptable.tsx" />
        <Grid item xs={12} md={8} data-sentry-element="Grid" data-sentry-source-file="IconBlockAdaptable.tsx">
          <Typography align={alignment} component={"div"} variant="h3" sx={{
          "& p": {
            margin: "0"
          }
        }} data-sentry-element="Typography" data-sentry-source-file="IconBlockAdaptable.tsx">
            <Markdown content={title} data-sentry-element="Markdown" data-sentry-source-file="IconBlockAdaptable.tsx" />
          </Typography>
          <Typography align={alignment} component={"div"} sx={{
          fontSize: "18px",
          fontWeight: {
            xs: 400
          }
        }} data-sentry-element="Typography" data-sentry-source-file="IconBlockAdaptable.tsx">
            <Markdown content={description} data-sentry-element="Markdown" data-sentry-source-file="IconBlockAdaptable.tsx" />
          </Typography>
        </Grid>
      </Stack>
    </Grid>;
}