import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import TitleBig from "@components/typography/TitleBig";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Image } from "components";
import { APITypes } from "utils/api.types";
export interface TroevenBlockProps {
  block: APITypes.Components.ContentBlocks.TroevenBlock;
}
export default function TroevenBlock({
  block
}: TroevenBlockProps) {
  const {
    title,
    blocks
  } = block;
  return <Box pb={20} pt={15} sx={{
    backgroundColor: colors.greyColor.light
  }} data-sentry-element="Box" data-sentry-component="TroevenBlock" data-sentry-source-file="TroevenBlock.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="TroevenBlock.tsx">
        <Stack gap={{
        xs: 4,
        md: 12
      }} data-sentry-element="Stack" data-sentry-source-file="TroevenBlock.tsx">
          <Stack alignItems={"center"} data-sentry-element="Stack" data-sentry-source-file="TroevenBlock.tsx">
            {title && <TitleBig text={title} />}
          </Stack>
          <Stack flexDirection={{
          xs: "column",
          md: "row"
        }} gap={2} data-sentry-element="Stack" data-sentry-source-file="TroevenBlock.tsx">
            {blocks.map((block, i) => <Stack key={i} flexDirection={"row"} gap={5}>
                <Image image={block.image} alt={block.image.alternativeText} style={{
              maxWidth: "40px",
              maxHeight: "40px"
            }} />
                <Stack alignItems={"start"}>
                  <Typography component={"h3"} variant={"h3"}>
                    {block.title}
                  </Typography>

                  <RegularText>
                    <Markdown content={block.content} />
                  </RegularText>
                </Stack>
              </Stack>)}
          </Stack>
        </Stack>
      </Container>
    </Box>;
}