import { colors } from "@components/Theme/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Image } from "components";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
export interface InternshipNewsBlockProps {
  block: APITypes.Components.ContentBlocks.InternshipNews;
}
const InternshipNewsBlock = ({
  block
}: InternshipNewsBlockProps) => {
  const {
    backgroundImage,
    title,
    button
  } = block;
  return <Container data-sentry-element="Container" data-sentry-component="InternshipNewsBlock" data-sentry-source-file="InternshipNewsBlock.tsx">
      <Box style={{
      position: "relative"
    }} data-sentry-element="Box" data-sentry-source-file="InternshipNewsBlock.tsx">
        <Box data-sentry-element="Box" data-sentry-source-file="InternshipNewsBlock.tsx">
          <Image style={{
          minHeight: 250,
          maxHeight: "100%",
          objectFit: "cover",
          width: "auto"
        }} image={backgroundImage} alt={backgroundImage?.name} layout="responsive" data-sentry-element="Image" data-sentry-source-file="InternshipNewsBlock.tsx" />
        </Box>
        <Box sx={{
        backgroundColor: colors.secondary.main,
        overflow: "hidden",
        width: {
          xs: "100%",
          lg: "600px"
        },
        left: {
          xs: "0",
          lg: "calc(5% - 54px)"
        },
        top: {
          xs: "0",
          lg: "calc(21% - 54px)"
        },
        bottom: {
          xs: "0",
          lg: "auto"
        },
        position: {
          xs: "relative",
          lg: "absolute"
        },
        color: "white",
        padding: "50px"
      }} data-sentry-element="Box" data-sentry-source-file="InternshipNewsBlock.tsx">
          <Stack gap={4} data-sentry-element="Stack" data-sentry-source-file="InternshipNewsBlock.tsx">
            <Typography variant="h2" style={{
            color: colors.white.main
          }} data-sentry-element="Typography" data-sentry-source-file="InternshipNewsBlock.tsx">
              {title}
            </Typography>
            <Stack flexDirection={"row"} data-sentry-element="Stack" data-sentry-source-file="InternshipNewsBlock.tsx">
              <Button variant="contained" component={NextLink} color="green" href={button.href} endIcon={<ArrowForwardIcon />} data-sentry-element="Button" data-sentry-source-file="InternshipNewsBlock.tsx">
                {button.title}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Container>;
};
export default InternshipNewsBlock;