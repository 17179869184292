import { colors } from "@components/Theme/colors";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Image } from "components";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";
export interface ImageWithContentProps {
  block: APITypes.Components.ContentBlocks.ImageWithContent;
}
const ImageWithContent = ({
  block
}: ImageWithContentProps) => {
  const {
    image,
    title,
    subTitle,
    content,
    backgroundColor,
    imagePosition
  } = block;
  const isLeft = imagePosition === "left";
  return <Box sx={{
    backgroundColor: backgroundColor ?? colors.white.main
  }} data-sentry-element="Box" data-sentry-component="ImageWithContent" data-sentry-source-file="ImageWithContent.tsx">
      <Container sx={{
      py: {
        xs: 8,
        lg: "120px"
      }
    }} data-sentry-element="Container" data-sentry-source-file="ImageWithContent.tsx">
        <Stack flexDirection={{
        xs: "column",
        md: isLeft ? "row" : "row-reverse"
      }} justifyContent={"stretch"} alignItems={"center"} gap={{
        xs: "20px",
        md: "100px"
      }} sx={{
        height: "100%"
      }} data-sentry-element="Stack" data-sentry-source-file="ImageWithContent.tsx">
          <Stack flex={1} height={"100%"} width={"100%"} data-sentry-element="Stack" data-sentry-source-file="ImageWithContent.tsx">
            <Image image={image} alt={image.name} style={{
            objectFit: "cover",
            height: "100%",
            width: "100%"
          }} data-sentry-element="Image" data-sentry-source-file="ImageWithContent.tsx" />
          </Stack>
          <Stack flex={1} height={"100%"} gap={{
          xs: "10px",
          md: "38px"
        }} data-sentry-element="Stack" data-sentry-source-file="ImageWithContent.tsx">
            <Typography variant="h3" style={{
            fontWeight: 400
          }} data-sentry-element="Typography" data-sentry-source-file="ImageWithContent.tsx">
              {subTitle}
            </Typography>
            <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="ImageWithContent.tsx">
              <SanitizeString content={title} data-sentry-element="SanitizeString" data-sentry-source-file="ImageWithContent.tsx" />
            </Typography>
            <Typography component={"div"} data-sentry-element="Typography" data-sentry-source-file="ImageWithContent.tsx">
              <SanitizeString content={content} data-sentry-element="SanitizeString" data-sentry-source-file="ImageWithContent.tsx" />
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default ImageWithContent;