import { colors } from "@components/Theme/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import NextLink from "next/link";
import Carousel from "react-material-ui-carousel";
import { APITypes } from "utils/api.types";
import { getBaseUrl } from "utils/fetchingFunctions";
export interface ExpectationsITClusterProps {
  block: APITypes.Components.ContentBlocks.ExpectationsITCluster;
}
type ArrayOfArrasMentorBlock = Array<APITypes.Components.MentorBlock[]>;
const ExpectationsITCluster = ({
  block
}: ExpectationsITClusterProps) => {
  const {
    title,
    description,
    previewBlock,
    backgroundImage,
    hexBackgroundColor,
    hexTextColor
  } = block;
  const theme = useTheme();
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.down("sm"));
  const rows = previewBlock.reduce(function (rows: ArrayOfArrasMentorBlock, key, index): ArrayOfArrasMentorBlock {
    index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key);
    return rows;
  }, []);
  return <Box sx={{
    backgroundColor: hexBackgroundColor,
    color: hexTextColor,
    backgroundImage: `url(${getBaseUrl().base}${backgroundImage.url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  }} py={{
    xs: 8,
    lg: 25
  }} data-sentry-element="Box" data-sentry-component="ExpectationsITCluster" data-sentry-source-file="ExpectationsITCluster.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="ExpectationsITCluster.tsx">
        <Stack alignItems="center" justifyContent="center" gap={4} sx={{
        flexDirection: {
          xs: "column",
          lg: "row"
        }
      }} data-sentry-element="Stack" data-sentry-source-file="ExpectationsITCluster.tsx">
          <Stack sx={{
          flex: 0.5,
          width: "100%"
        }} gap={4} data-sentry-element="Stack" data-sentry-source-file="ExpectationsITCluster.tsx">
            <Typography variant="h2" style={{
            color: colors.white.main
          }} data-sentry-element="Typography" data-sentry-source-file="ExpectationsITCluster.tsx">
              {title}
            </Typography>
            <Typography data-sentry-element="Typography" data-sentry-source-file="ExpectationsITCluster.tsx">{description}</Typography>
          </Stack>
          <Stack sx={{
          flex: 1,
          width: "100%"
        }} data-sentry-element="Stack" data-sentry-source-file="ExpectationsITCluster.tsx">
            <Carousel sx={{
            minHeight: isExtraSmallSize ? "390px" : "450px"
          }} swipe={true} autoPlay={false} navButtonsAlwaysInvisible={true} indicators={isExtraSmallSize} indicatorIconButtonProps={{
            style: {
              color: "grey",
              padding: "1px"
            }
          }} activeIndicatorIconButtonProps={{
            style: {
              color: "white"
            }
          }} navButtonsProps={{
            style: {
              borderRadius: 0,
              backgroundColor: "transparent",
              color: "black"
            }
          }} data-sentry-element="Carousel" data-sentry-source-file="ExpectationsITCluster.tsx">
              {isExtraSmallSize ? previewBlock.map((row, i) => <PreviewBlock key={i} previewBlock={row} />) : rows.map((row, i) => <Grid container direction={"row"} spacing={4} key={i}>
                      {row.map((col, index) => <Grid item xs={12} lg={6} key={index} sx={{
                display: "flex"
              }} alignItems="stretch" justifyContent="center">
                          <PreviewBlock key={i} previewBlock={col} />
                        </Grid>)}
                    </Grid>)}
            </Carousel>
          </Stack>
        </Stack>
      </Container>
    </Box>;
};
export default ExpectationsITCluster;
function PreviewBlock({
  previewBlock
}: {
  previewBlock: APITypes.Components.MentorBlock;
}) {
  return <Card sx={{
    borderRadius: 0,
    display: "flex",
    flexDirection: "column"
  }} data-sentry-element="Card" data-sentry-component="PreviewBlock" data-sentry-source-file="ExpectationsITCluster.tsx">
      <CardMedia component="img" image={`${getBaseUrl().base}${previewBlock.image.url}`} alt={previewBlock.image.name} data-sentry-element="CardMedia" data-sentry-source-file="ExpectationsITCluster.tsx" />
      <CardActionArea component={NextLink} href={previewBlock.href} sx={{
      borderRadius: 0,
      display: "flex",
      flexDirection: "column"
    }} data-sentry-element="CardActionArea" data-sentry-source-file="ExpectationsITCluster.tsx">
        <CardContent sx={{
        borderRadius: 0,
        flex: 1,
        height: "100%"
      }} data-sentry-element="CardContent" data-sentry-source-file="ExpectationsITCluster.tsx">
          <Stack gap={4} data-sentry-element="Stack" data-sentry-source-file="ExpectationsITCluster.tsx">
            <Typography align={"left"} variant="h5" color={"primary"} data-sentry-element="Typography" data-sentry-source-file="ExpectationsITCluster.tsx">
              {previewBlock.title}
            </Typography>
            <Typography variant="body1" color="primary" data-sentry-element="Typography" data-sentry-source-file="ExpectationsITCluster.tsx">
              {previewBlock.subTitle}
            </Typography>
          </Stack>
        </CardContent>
        <Stack flexDirection={"row"} justifyContent={"end"} alignItems={"end"} sx={{
        flex: 1,
        height: "100%",
        width: "100%"
      }} data-sentry-element="Stack" data-sentry-source-file="ExpectationsITCluster.tsx">
          <Button variant="contained" sx={{
          p: 1
        }} data-sentry-element="Button" data-sentry-source-file="ExpectationsITCluster.tsx">
            <ArrowForwardIcon data-sentry-element="ArrowForwardIcon" data-sentry-source-file="ExpectationsITCluster.tsx" />
          </Button>
        </Stack>
      </CardActionArea>
    </Card>;
}