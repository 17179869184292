import { colors } from "@components/Theme/colors";
import { INTERNSHIP_FR, INTERNSHIP_NL, SPONTANEOUS_APPLICATION } from "@components/VacatureDetail/VacatureDetailDescriptionBlock/VacatureDetailDescriptionBlock";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Card, CardContent, Chip, Stack, Typography } from "@mui/material";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { APITypes, getClusterTranslation } from "utils/api.types";
interface JobCardProps {
  position: APITypes.Components.Job;
  slug: string;
}
export const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};
export const cleanLocation = (input: string): string => {
  const pieces = input.split("--");
  if (!pieces) {
    return input;
  }
  return pieces[0];
};
const cleanCluster = (cluster: string) => {
  return capitalize(cluster.replace("-cluster", "").replace("-", " "));
};
export const JobCard = ({
  position,
  slug
}: JobCardProps) => {
  const {
    locale
  } = useRouter();
  const location = position.location ? capitalize(cleanLocation(position.location)) : "";
  const cluster = position.cluster ? cleanCluster(position.cluster) : "";
  const isSpontaneousApplication = [SPONTANEOUS_APPLICATION].includes(position.jobsId);
  const isInternship = [INTERNSHIP_FR, INTERNSHIP_NL].includes(position.jobsId);
  return <NextLink style={{
    textDecoration: "none"
  }} href={{
    pathname: `${slug}/${position.slug}`
  }} prefetch={true} data-sentry-element="NextLink" data-sentry-component="JobCard" data-sentry-source-file="JobCard.tsx">
      <Card variant="outlined" sx={{
      border: isSpontaneousApplication ? `4px solid ${colors.secondary.main}` : 0,
      boxShadow: 0,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      py: "40px",
      px: "28px",
      justifyContent: "space-between"
    }} data-sentry-element="Card" data-sentry-source-file="JobCard.tsx">
        <CardContent sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        padding: 0
      }} data-sentry-element="CardContent" data-sentry-source-file="JobCard.tsx">
          {location && <Typography component={"div"} sx={{
          color: colors.green.main
        }}>
              <Box sx={{
            display: "flex",
            alignItems: "center"
          }}>
                <LocationOnIcon />
                {location}
              </Box>
            </Typography>}

          <Stack spacing={2} data-sentry-element="Stack" data-sentry-source-file="JobCard.tsx">
            <Typography variant="h3" style={{
            fontFamily: "Goodall Pro"
          }} data-sentry-element="Typography" data-sentry-source-file="JobCard.tsx">
              {position.title}
            </Typography>
            <Stack flexDirection="row" gap={2} flexWrap={"wrap"} data-sentry-element="Stack" data-sentry-source-file="JobCard.tsx">
              {location && <Chip label={
            // Weird shit to compensate for weird shit in Connexys
            !isSpontaneousApplication ? capitalize(location) : locale === "fr-FR" ? "Bruxelles-Wallonie" : "Vlaanderen"} />}
              {!isSpontaneousApplication && !isInternship && cluster && locale && <Chip label={getClusterTranslation(locale, cluster)} />}
            </Stack>
          </Stack>
          <Typography component={"div"} sx={{
          fontWeight: 600,
          "& li::marker": {
            content: "'━'",
            color: colors.secondary.main
          },
          "& li": {
            pl: "10px"
          }
        }} data-sentry-element="Typography" data-sentry-source-file="JobCard.tsx">
            <div dangerouslySetInnerHTML={{
            __html: position.profileTop
          }} />
          </Typography>
        </CardContent>
        <Stack flexDirection="row" justifyContent={"end"} alignItems={"end"} data-sentry-element="Stack" data-sentry-source-file="JobCard.tsx">
          <Button variant="contained" sx={{
          px: 1.5
        }} data-sentry-element="Button" data-sentry-source-file="JobCard.tsx">
            <ArrowForwardIcon fontSize={"small"} data-sentry-element="ArrowForwardIcon" data-sentry-source-file="JobCard.tsx" />
          </Button>
        </Stack>
      </Card>
    </NextLink>;
};