import { APITypes } from "utils/api.types";
import { mabBlockStyles } from "./mapBlock.styles";
import { Container, Divider, Link, Stack, Typography } from "@mui/material";
import { SanitizeString } from "utils/SanitizeString";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NextImage from "next/image";
import { APP_URLS } from "utils/fetchingFunctions";
import { Map } from "components/blocks/MapBlock/Map";
interface MapBlockProps {
  block: APITypes.Components.ContentBlocks.MapBlock;
}
export const MapBlock = ({
  block
}: MapBlockProps) => {
  const {
    title,
    locations,
    description
  } = block;
  return <Stack sx={mabBlockStyles.stackContainer} data-sentry-element="Stack" data-sentry-component="MapBlock" data-sentry-source-file="MapBlock.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="MapBlock.tsx">
        <Stack gap={"50px"} data-sentry-element="Stack" data-sentry-source-file="MapBlock.tsx">
          <Stack data-sentry-element="Stack" data-sentry-source-file="MapBlock.tsx">
            <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="MapBlock.tsx">{title}</Typography>
          </Stack>
          <Stack flexDirection={{
          sm: "column",
          md: "row"
        }} gap={"50px"} data-sentry-element="Stack" data-sentry-source-file="MapBlock.tsx">
            <Stack maxWidth={"500px"} gap={"24px"} data-sentry-element="Stack" data-sentry-source-file="MapBlock.tsx">
              <Divider sx={mabBlockStyles.divider} data-sentry-element="Divider" data-sentry-source-file="MapBlock.tsx" />
              <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="MapBlock.tsx">{description.title}</Typography>
              <Typography component="div" data-sentry-element="Typography" data-sentry-source-file="MapBlock.tsx">
                <SanitizeString content={description.description} data-sentry-element="SanitizeString" data-sentry-source-file="MapBlock.tsx" />
              </Typography>
              <Link sx={mabBlockStyles.link} href={description.button.href} target="_blank" data-sentry-element="Link" data-sentry-source-file="MapBlock.tsx">
                {description.button.title}
                <ArrowForwardIcon fontSize="small" data-sentry-element="ArrowForwardIcon" data-sentry-source-file="MapBlock.tsx" />
              </Link>
              <Divider sx={mabBlockStyles.divider} data-sentry-element="Divider" data-sentry-source-file="MapBlock.tsx" />
              <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="MapBlock.tsx">{description.list.title}</Typography>
              <Stack gap={"24px"} data-sentry-element="Stack" data-sentry-source-file="MapBlock.tsx">
                {description.list.icons.map((item, i) => <Stack key={i} flexDirection={"row"} gap={"16px"} alignItems={"center"}>
                    <NextImage width={44} height={38} alt={item.icon.name} src={`${APP_URLS.base}${item.icon.url}`} />
                    <Typography sx={mabBlockStyles.listText}>
                      {item.text}
                    </Typography>
                  </Stack>)}
              </Stack>
            </Stack>
            <Stack width={"100%"} data-sentry-element="Stack" data-sentry-source-file="MapBlock.tsx">
              <Map locations={locations} data-sentry-element="Map" data-sentry-source-file="MapBlock.tsx" />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>;
};