import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter';
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type { AppProps } from "next/app";
import Script from "next/script";
import { useState } from "react";
import "../public/fonts/stylesheet.css";

//TODO: this one is used for game page, but that's not live yet and it is breaking the cards
//import "../public/assets/styles/css/main.min.css";

import theme from "components/Theme/theme";

// const theme = createTheme({
//     typography: {
//         allVariants: {
//             fontFamily: "Proxima Soft",
//         },
//     },
// });

export default function App(props: AppProps) {
  const {
    Component,
    pageProps
  } = props;
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  if (!process.env.NEXT_PUBLIC_CONNEXYS_URL) {
    console.warn("CONNEXYS_URL is not set in .env file. Please set it to enable Connexys form functionality.");
  }
  return <AppCacheProvider {...props} data-sentry-element="AppCacheProvider" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
        <LocalizationProvider dateAdapter={AdapterDayjs} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
          {process.env.NEXT_PUBLIC_CONNEXYS_URL && <Script id="connexys-form-script" src={`${process.env.NEXT_PUBLIC_CONNEXYS_URL}resource/cxsrec__cxsForm`} type="text/javascript" onLoad={() => {
          setIsScriptLoaded(true);
        }} />}
          <Component {...pageProps} isScriptLoaded={isScriptLoaded} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
        </LocalizationProvider>
      </ThemeProvider>
    </AppCacheProvider>;
}