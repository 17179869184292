import NextImage from "next/image";
import { APP_URLS } from "utils/fetchingFunctions";
interface ImageProps {
  className?: string;
  image?: any;
  layout?: "responsive" | "fill";
  alt?: string;
  style?: any;
  onLoad?: () => void;
  preload?: boolean;
}
const Image = ({
  className,
  image,
  layout,
  alt,
  style,
  preload = false,
  onLoad
}: ImageProps) => {
  if (!image) {
    return null;
  }
  const {
    url,
    width,
    height,
    alternativeText = ""
  } = image;
  const props = {
    className,
    src: `${APP_URLS.base}${url}`,
    alt: alt ?? alternativeText,
    width,
    height,
    layout,
    style,
    priority: preload,
    onLoad
  };
  if (!width || !height) {
    props.layout = "fill";
  }
  return <NextImage id={"customImage"} {...props} data-sentry-element="NextImage" data-sentry-component="Image" data-sentry-source-file="Image.tsx" />;
};
export { Image };