import { ContentBuilder } from "@components/ContentBuilder";
import { Layout } from "@components/Layout";
import { GetStaticProps } from "next";
import { APITypes } from "utils/api.types";
import { fetchPageBySlug } from "utils/fetchingFunctions";
interface PageProps {
  page: APITypes.Page;
}
export default function Page({
  page
}: PageProps) {
  return <Layout meta={page?.seo} data-sentry-element="Layout" data-sentry-component="Page" data-sentry-source-file="index.tsx">
      {/*NavBar*/}
      {page.navigationBar && <ContentBuilder contentBlocks={page.navigationBar.content} slug={page.slug} localizations={page.localizations} />}
      <ContentBuilder contentBlocks={page.content} slug={page.slug} localizations={page.localizations} data-sentry-element="ContentBuilder" data-sentry-source-file="index.tsx" />
      {/*Contact*/}
      {page.contact && <ContentBuilder contentBlocks={page.contact.content} slug={page.slug} localizations={page.localizations} />}
      {/*Footer*/}
      {page.footer && <ContentBuilder contentBlocks={page.footer.content} slug={page.slug} localizations={page.localizations} />}
    </Layout>;
}
export const getStaticProps: GetStaticProps<{
  page?: APITypes.Page;
}, {
  slug: string;
}> = async ({
  locale,
  defaultLocale
}) => {
  const localeToFetch = (locale ?? defaultLocale) as any;
  const {
    data: indexPage
  } = await fetchPageBySlug("index", localeToFetch);
  const excludedLocalesForIndexPage = ["default", "en"];
  // Exception for here, because we have some pages (IT) in english, but all others are in FR or NL
  if ((!indexPage || indexPage.length === 0) && !excludedLocalesForIndexPage.includes(localeToFetch)) throw new Error(`Page with slug 'index' doesn't exist for locale ${localeToFetch}!`);
  if (indexPage && indexPage.length > 0) {
    return {
      props: {
        page: indexPage[0]
      }
    };
  }
  return {
    notFound: true
  };
};