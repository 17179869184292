import { default as ReactMarkdown } from "react-markdown";
import remarkGfm from "remark-gfm";
type MarkdownProps = {
  className?: string;
  content: string;
  isOpenLinkNewTab?: boolean;
};
const Markdown = ({
  content
}: MarkdownProps) => {
  if (!content) return null;
  if (content === "") return null;
  return <ReactMarkdown urlTransform={url => {
    // TODO: support opening links in new tab
    //console.log("URL", url);
    return url;
  }} remarkPlugins={[remarkGfm]} data-sentry-element="ReactMarkdown" data-sentry-component="Markdown" data-sentry-source-file="MarkdownComponent.tsx">
      {content}
    </ReactMarkdown>;
};
export { Markdown };