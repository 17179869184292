import { Card, Stack, Typography, Button } from "@mui/material";
import { APITypes } from "utils/api.types";
import NextLink from "next/link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { locationCardStyles } from "./locationCard.styles";
interface LocationCardProps {
  location: APITypes.Components.ILocationMapBlock;
  isRichText: boolean;
}
export const LocationCard = ({
  location,
  isRichText
}: LocationCardProps) => {
  return <Card sx={locationCardStyles.card} data-sentry-element="Card" data-sentry-component="LocationCard" data-sentry-source-file="LocationCard.tsx">
      <Stack gap={"20px"} data-sentry-element="Stack" data-sentry-source-file="LocationCard.tsx">
        <Stack gap={"24px"} data-sentry-element="Stack" data-sentry-source-file="LocationCard.tsx">
          <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="LocationCard.tsx">{location.title}</Typography>
          <Typography component={"div"} data-sentry-element="Typography" data-sentry-source-file="LocationCard.tsx">{location.address}</Typography>
        </Stack>
        <Button component={NextLink} size="small" href={location.button.href} target="_blank" sx={locationCardStyles.button} endIcon={<ArrowForwardIcon />} data-sentry-element="Button" data-sentry-source-file="LocationCard.tsx">
          {location.button.title}
        </Button>
      </Stack>
    </Card>;
};