import { Typography } from "@mui/material";
export interface ITitleBiggestProps {
  titleSize: "h1" | "h2";
  text: string;
  sx?: any;
}
export default function TitleBiggest({
  titleSize,
  text,
  sx
}: ITitleBiggestProps) {
  return <Typography component={"h1"} variant={titleSize} sx={{
    ...sx
  }} data-sentry-element="Typography" data-sentry-component="TitleBiggest" data-sentry-source-file="TitleBiggest.tsx">
      {text}
    </Typography>;
}