import { colors } from "@components/Theme/colors";

export const mapStyles = {
  boxWrapper: {
    "& .mapboxgl-popup-close-button": {
      backgroundColor: colors.secondary.main,
      color: colors.white.main,
      fontSize: "1.5rem",
      px: 1,
      " &:hover": {
        color: colors.white.main,
        backgroundColor: colors.secondary.main,
      },
    },

    "& .mapboxgl-popup-content": {
      padding: 0,
    },

    "& .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip ": {
      borderTopColor: "transparent",
    },
  },
  mapBoxMap: {
    width: "100%",
    height: "600px",
  },
};
