import { Typography } from "@mui/material";
export interface ITitleBig {
  text: string;
  sx?: Record<string, any>;
  style?: Record<string, any>;
}
export default function TitleBig({
  text,
  sx,
  style
}: ITitleBig) {
  return <Typography component="h2" variant="h2" sx={{
    ...sx
  }} style={{
    ...style
  }} data-sentry-element="Typography" data-sentry-component="TitleBig" data-sentry-source-file="TitleBig.tsx">
      {text}
    </Typography>;
}