import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import { Box, Container, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import LandingPageContactForm from "./LandingPageContactForm";
export interface LandingPageContactBlockProps {
  block: APITypes.Components.ContentBlocks.LandingPageContactFrom;
}
const LandingPageContactBlock = ({
  block
}: LandingPageContactBlockProps) => {
  const {
    title,
    description
  } = block;
  return <Box id="form" sx={{
    backgroundColor: colors.greyColor.light
  }} py={{
    xs: 8,
    lg: 20
  }} data-sentry-element="Box" data-sentry-component="LandingPageContactBlock" data-sentry-source-file="LandingPageContactBlock.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="LandingPageContactBlock.tsx">
        <Stack gap={4} data-sentry-element="Stack" data-sentry-source-file="LandingPageContactBlock.tsx">
          <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="LandingPageContactBlock.tsx">{title}</Typography>
          <Typography component={"div"} sx={{
          "& a": {
            fontWeight: 600,
            color: colors.secondary.main
          }
        }} data-sentry-element="Typography" data-sentry-source-file="LandingPageContactBlock.tsx">
            <Markdown content={description} data-sentry-element="Markdown" data-sentry-source-file="LandingPageContactBlock.tsx" />
          </Typography>
          <Container maxWidth="md" data-sentry-element="Container" data-sentry-source-file="LandingPageContactBlock.tsx">
            <LandingPageContactForm form={block} data-sentry-element="LandingPageContactForm" data-sentry-source-file="LandingPageContactBlock.tsx" />
          </Container>
        </Stack>
      </Container>
    </Box>;
};
export default LandingPageContactBlock;