import { APITypes } from "utils/api.types";
import LandingPageHero from "./LandingPageHero/LandingPageHero";
export interface ClusterHeroProps {
  block: APITypes.Components.ContentBlocks.ClusterHero;
}
const ClusterHero = ({
  block
}: ClusterHeroProps) => {
  return <LandingPageHero block={block as any} data-sentry-element="LandingPageHero" data-sentry-component="ClusterHero" data-sentry-source-file="ClusterHero.tsx" />;
};
export default ClusterHero;