import VideoItem from "@components/blocks/VideoCarousel/VideoItem/VideoItem";
import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/skyblue";
import { useRef, useState } from "react";
import { APITypes } from "utils/api.types";
export interface IVideoCarousel {
  block: APITypes.Components.ContentBlocks.VideoCarousel;
}
export default function VideoCarousel({
  block
}: IVideoCarousel) {
  const {
    videos,
    titleText
  } = block;
  const mainRef = useRef<Splide>(null);
  const [playerPlayVideo, setPlayerPlayVideo] = useState<any>(() => {});
  const [isHideBox, setIsHideBox] = useState(false);
  const handleBack = () => {
    if (mainRef.current) {
      mainRef.current.go("<");
      playerPlayVideo?.pauseVideo();
    }
  };
  const handleNext = () => {
    if (mainRef.current) {
      mainRef.current.go(">");
      playerPlayVideo.pauseVideo();
    }
  };
  return <Container disableGutters sx={{
    "& .splide": {
      width: "100%",
      padding: 0,
      margin: 0
    },
    marginTop: {
      xs: 0,
      md: 5
    },
    display: "flex",
    justifyContent: "center"
  }} data-sentry-element="Container" data-sentry-component="VideoCarousel" data-sentry-source-file="VideoCarousel.tsx">
      <Box sx={{
      position: "relative",
      width: {
        xs: "100%",
        md: "80%"
      }
    }} data-sentry-element="Box" data-sentry-source-file="VideoCarousel.tsx">
        <Splide ref={mainRef} options={{
        type: "loop",
        perPage: 1,
        focus: "center",
        arrows: false,
        pagination: false
      }} data-sentry-element="Splide" data-sentry-source-file="VideoCarousel.tsx">
          {videos.map((item, i) => <SplideSlide key={i}>
              <VideoItem video={item.video} setPlayerPlayVideo={setPlayerPlayVideo} setIsHideBox={setIsHideBox} key={i} />
            </SplideSlide>)}
        </Splide>
        <Container sx={{
        position: {
          xs: "flex",
          md: "absolute"
        },
        display: isHideBox ? "none" : "flex",
        top: 0,
        left: 0,
        zIndex: 2,
        width: "100%",
        height: {
          xs: "unset",
          md: "100%"
        },
        px: {
          xs: 0,
          md: "24px"
        },
        pointerEvents: "none",
        wordBreak: "break-word"
      }} data-sentry-element="Container" data-sentry-source-file="VideoCarousel.tsx">
          <Stack alignItems={"end"} justifyContent={"end"} height={"100%"} data-sentry-element="Stack" data-sentry-source-file="VideoCarousel.tsx">
            <Stack sx={{
            padding: {
              xs: 2,
              md: 4
            },
            background: colors.secondary.main,
            color: colors.white.main,
            maxWidth: {
              xs: "100%",
              lg: "40%"
            }
          }} data-sentry-element="Stack" data-sentry-source-file="VideoCarousel.tsx">
              <Typography variant="h2" style={{
              color: colors.white.main,
              wordBreak: "break-word"
            }} data-sentry-element="Typography" data-sentry-source-file="VideoCarousel.tsx">
                {titleText.title}
              </Typography>
              <Typography component={"div"} variant="body1" data-sentry-element="Typography" data-sentry-source-file="VideoCarousel.tsx">
                <Markdown content={titleText.text} data-sentry-element="Markdown" data-sentry-source-file="VideoCarousel.tsx" />
              </Typography>
              {videos.length === 1 ? <Stack></Stack> : <Stack flexDirection={"row"} justifyContent={"end"} gap={2}>
                  <Button sx={{
                px: 1,
                py: 0.5,
                pointerEvents: "auto"
              }} color="green" variant="contained" onClick={handleBack}>
                    <ArrowBackIcon fontSize="small" />
                  </Button>
                  <Button sx={{
                px: 1,
                py: 0.5,
                pointerEvents: "auto"
              }} color="green" onClick={handleNext} variant="contained">
                    <ArrowForwardIcon fontSize="small" />
                  </Button>
                </Stack>}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Container>;
}