import { Markdown } from "@components/Markdown/MarkdownComponent";
import { colors } from "@components/Theme/colors";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Divider, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";
export interface StageCollapsibleListProps {
  block: APITypes.Components.ContentBlocks.StageCollapsibleList;
}
const StageCollapsibleList = ({
  block
}: StageCollapsibleListProps) => {
  const {
    list,
    title,
    description
  } = block;
  return <Stack data-sentry-element="Stack" data-sentry-component="StageCollapsibleList" data-sentry-source-file="StageCollapsibleList.tsx">
      <Box sx={{
      backgroundColor: list[0].hexBackgroundColor
    }} py={{
      xs: 6,
      md: 14
    }} data-sentry-element="Box" data-sentry-source-file="StageCollapsibleList.tsx">
        <Container data-sentry-element="Container" data-sentry-source-file="StageCollapsibleList.tsx">
          <Stack gap={{
          xs: 4,
          md: "230px"
        }} flexDirection={{
          xs: "column",
          md: "row"
        }} data-sentry-element="Stack" data-sentry-source-file="StageCollapsibleList.tsx">
            <Stack sx={{
            flex: 1,
            maxWidth: {
              xs: "100%",
              md: "587px"
            }
          }} gap={4} data-sentry-element="Stack" data-sentry-source-file="StageCollapsibleList.tsx">
              <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="StageCollapsibleList.tsx">{title}</Typography>
              {description && <Typography component={"div"}>
                  <SanitizeString content={description} />
                </Typography>}
            </Stack>
            <Stack sx={{
            flex: 1
          }} gap={2} data-sentry-element="Stack" data-sentry-source-file="StageCollapsibleList.tsx">
              {list.map((item, i) => <Stack key={i} sx={{
              flex: 1,
              width: "100%"
            }}>
                  <Accordion defaultExpanded={i === 0} sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                boxShadow: "none"
              }}>
                    <AccordionSummary expandIcon={<ExpandLessIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    color: colors.secondary.main,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    "& .MuiSvgIcon-root": {
                      fontSize: 40
                    }
                  }
                }}>
                      <Typography variant="h5" sx={{
                    color: colors.primary.main,
                    pr: 3
                  }}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component={"div"} sx={{
                    maxWidth: {
                      xs: "100%",
                      md: "492px"
                    }
                  }}>
                        <Markdown content={item.description} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Stack>)}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Stack>;
};
export default StageCollapsibleList;